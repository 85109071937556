// @ts-check

import GridExportButton from 'components/Shared/DataGrid/GridExportButton';
import { useTaskDataGridContext } from './TaskDataGrid.context';

const EXPORTED_ROW_COUNT = 125;

export default function TaskDataGridExportButton() {
    const { gridRef, setRowCount } = useTaskDataGridContext();

    return (
        <GridExportButton
            gridRef={gridRef}
            setRowCount={setRowCount}
            exportRowCount={EXPORTED_ROW_COUNT}
            fileName="ZERO Todo Export"
        />
    );
}
