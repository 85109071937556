// @ts-check

import { useZeroContext } from "components/ZeroContext";
import TaskDetailModal from "../TaskDetailModal";
import { useTaskDataGridContext } from "./TaskDataGrid.context";
import { EventNames } from "./TaskDataGrid.utils";
import ConfirmModal from "components/Shared/ConfirmModal";
import { tasksApi } from "api/zero-api";
import NotificationAlert from "other/NotificationAlert";
import { getErrorMessageFromResponse } from "other/Helper";
import DeleteTaskConfirmationModal from "../DeleteTaskModal";

export function TaskDataGridModals() {
    const { events } = useZeroContext();
    const { activeModal, setActiveModal } = useTaskDataGridContext();

    if (!activeModal) {
        return null;
    }

    const {key, task} = activeModal;
    const onClose = () => setActiveModal(null);
    const onMutation = () => events.emit(EventNames.REFRESH_DATA);

    const props = {task, onClose, onMutation};

    switch (key) {
        case "detail":
            return <TaskDetailModal {...props}  />;
        case "delete":
            return <DeleteTaskConfirmationModal {...props} />
        default:
            console.error(`Unknown task table modal '${key}'`);
    }

    return null;
}
