// @ts-check
import { DataGridContext, DataGridContextProvider } from "components/Shared/ZeroDataGrid/ZeroDataGrid.context";
import { useCallback, useContext, useMemo, useState } from "react";

/** @returns {BaseDataGridContextValue & ReturnType<useContextValue>} */
export function useTaskDataGridContext() {
    return useContext(DataGridContext);
}

export function TaskDataGridContextProvider({children}) {
    const ctxValue = useContextValue();

    return (
        <DataGridContextProvider dataGridName="tasks" customValue={ctxValue}>
            {children}
        </DataGridContextProvider>
    )
}

const initialFilterState = {
    search: '',
    period: '',
    status: '',
    allTasks: '',
}

function useContextValue() {
    const [filters, setFilters] = useState(initialFilterState);

    const setFilter = useCallback((key, value) => {
        setFilters(filters => ({...filters, [key]: value}));
    }, [setFilters]);

    return useMemo(() => ({filters, setFilter}), [filters, setFilter]);
}