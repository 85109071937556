import {safe_get} from "other/Helper";
import {useEffect, useState, useMemo} from "react";

export default function UserAvatar({user, imgClass = '', initialsClass = '', style = {}}) {
    const [imageSource, setImageSource] = useState(null);

    const avatarData = useMemo(() => {
        return safe_get(user, 'avatar_data', {});
    }, [user]);

    useEffect(() => {
        if (avatarData.img_uploaded) {
            const url = (
                safe_get(avatarData, 'urls.small', null)
                || safe_get(avatarData, 'urls.medium', null)
                || safe_get(avatarData, 'urls.original', null)
            );
            if (url) {
                setImageSource(url);
            } else {
                setImageSource(null);
            }
        } else {
            setImageSource(null);
        }
    }, [avatarData]);

    return (
        imageSource ? (
            <img src={imageSource} className={imgClass} style={style} onError={() => setImageSource(null)}
                 alt="Profile Pic"/>
        ) : (
            <div className={"default-avatar-circle " + initialsClass}
                 style={{...style, backgroundColor: avatarData.bg_color}}>
                <p className="initials">{avatarData.initials}</p>
            </div>
        )
    );
}
