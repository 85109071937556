// @ts-check

import { cssClasses } from "other/Helper";

export default function FormGroup({label, required=false, error=null, children}) {
    const labelClassName = cssClasses({
        $base: 'text-bold mar-btm-0',
        required,
    });

    return (
        <div className={'form-group flex-col mar-btm-10'}>
            <label className={labelClassName} style={{display: 'block'}}>
                {label}
            </label>
            {children}
            { error &&
                <small className="error">{error}</small>
            }
        </div>
    );
}