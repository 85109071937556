// @ts-check

export const TASK_STATUS_OPTIONS = [
    {value: 'open', label: 'Open'},
    {value: 'closed', label: 'Closed'},
];

export const TASK_STATUS_FILTER_OPTIONS = [
    {value: '', label: 'All status'},
    ...TASK_STATUS_OPTIONS,
]