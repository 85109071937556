// @ts-check
import Rollbar from "rollbar"
import { getReduxState, isProduction } from "./Helper"

export function getErrorHandler(user) {
    if (window.location.hostname.includes("localhost")) {
        return null;
    }

    let person;

    if (user) {
        person = {
            id: user.uuid ?? user.user_uuid,
            username: `${user.first_name} ${user.last_name}`.trim(),
            email: user.email ?? "",
        }
    }

    return new Rollbar({
        accessToken: '3dba3ddccb8b475994cbfa0165e6738d',
        captureUncaught: true,
        captureUnhandledRejections: true,
        ignoredMessages: ["ResizeObserver loop limit exceeded"],
        payload: {
            person,
            environment: isProduction() ? "production" : "staging",
            client: {
                javascript: {
                    source_map_enabled: true,
                }
            }
        }
    });
}

/**
 * @param {string} message
 * @param  {...Rollbar.LogArgument} args 
 */
export function reportError(message, ...args) {
    const user = getReduxState().user.user;
    const rollbar = getErrorHandler(user);
    console.error(message, ...args);
    if (rollbar) {
        rollbar.error(message, ...args);
    } else {
        // check for any callbacks, like rollbar would do
        for (const arg of args) {
            if (typeof(arg) === 'function') {
                arg(null, null);
            }
        }
    }
}