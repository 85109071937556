import React from 'react';

import {mainContentContainerClass, safe_get} from '../other/Helper';

import {Result} from 'antd';

import '../assets/css/antd-custom.css'
import LogoutButton from 'components/Shared/LogoutButton';
import Button from 'components/Shared/Button';

class Error403 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div id="content-container" className="container-with-header">
                <div className={mainContentContainerClass()} style={{marginLeft: "0px"}}>
                    <div id="page-content" style={{paddingTop: "10px"}}>
                        <div className="panel bulletin-post-border">
                            <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you do not have access to this page."
                                extra={
                                    <div className="flex flex-justify-center" style={{gap: '1rem'}}>
                                        <LogoutButton />
                                        <Button type="primary" onClick={() => window.location.href = '/'}>Back to ZERO</Button>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Error403;