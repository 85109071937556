// @ts-check

import { useZeroEventListener } from "components/ZeroContext";
import { useOrgAndUserCacheKey } from "hooks/reduxHooks";
import { isEqual } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * @typedef {ReturnType<useDataGridContextBase>} BaseDataGridContextValue
 */

/**
 * @param {string} dataGridName 
 */
export function useDataGridContextBase(dataGridName) {
    const [settings, setSettings] = useDataGridSettings(dataGridName);
    const [rowCount, setRowCount] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    /** @type {import("react").MutableRefObject<AgGridReactLib.AgGridReact>} */
    const gridRef = useRef();

    return useMemo(() => ({
        settings,
        setSettings,
        rowCount,
        setRowCount,
        activeModal,
        setActiveModal,
        gridRef,
    }), [settings, setSettings, rowCount, setRowCount, activeModal, setActiveModal, gridRef]);
}

/**
 * @param {DataGridSetupParams} params 
 */
export function useDataGridSetup({eventNames, gridRef, colDefs, getRows, setSettings, onRefresh, onReset, disableDataLoading, autoLoadData}) {
    const onGridReady = useCallback((/** @type {AgGrid.GridReadyEvent} */ event) => {
        /** @type {AgGrid.IDatasource} */
        const dataSource = {
            rowCount: undefined,
            getRows,
        };
        event.api.setGridOption("datasource", dataSource);
    }, [getRows]);

    const loadGridData = useCallback((getRows) => {
        const agApi = gridRef.current?.api;
        if (agApi) {
            const datasource = {
                rowCount: undefined,
                getRows,
            };
            agApi.setGridOption("datasource", datasource);
        }
    }, [gridRef]);

    useEffect(() => {
        if (!autoLoadData) return;
        loadGridData(getRows);
    }, [autoLoadData, loadGridData, getRows]);

    const onStateUpdated = useCallback((/** @type {AgGrid.StateUpdatedEvent} */ event) => {
        if (event.sources.includes("columnOrder")) {
            // @ts-expect-error
            const columnDefsOrder = colDefs.map(def => def.field);
            const newColumnOrder = event.state.columnOrder.orderedColIds;
            const isDefaultOrder = isEqual(columnDefsOrder, newColumnOrder);
            if (isDefaultOrder) {
                setSettings('columnOrder', null);
            } else {
                setSettings('columnOrder', event.state.columnOrder);
            }
        }
        
        if (event.sources.includes("sort")) {
            setSettings('sort', event.state.sort);
        }
    }, [setSettings]);

    const resetTable = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        onReset?.();
        setSettings('columnOrder', null);
        grid.api.resetColumnState();
    }, [setSettings]);

    const refreshData = useCallback(() => {
        const grid = gridRef.current;
        if (!grid || disableDataLoading) {
            return;
        }
        onRefresh?.();
        grid.api.refreshInfiniteCache();
    }, [disableDataLoading]);

    useZeroEventListener(eventNames.RESET_TABLE, resetTable);
    useZeroEventListener(eventNames.REFRESH_DATA, refreshData);

    return {
        onGridReady,
        onStateUpdated,
        loadGridData,
    };
}

/**
 * @param {string} prefix
 * @returns {DataGridEvents}
 */
export function generateDataGridEventNames(prefix) {
    return {
        // Emitted when "Reset table" is clicked. Resets sorting and column order.
        RESET_TABLE: `${prefix}:resetTable`,
        // Emitted when table should refetch datasource
        REFRESH_DATA: `${prefix}:refreshData`,
    }
}

/**
 * 
 * @param {string} dataGridName 
 * @returns {[any, (key: string, value: any) => void]}
 */
function useDataGridSettings(dataGridName) {
    const cacheKeyPrefix = useOrgAndUserCacheKey();
    const cacheKey = `zero-data-grid:${cacheKeyPrefix}:${dataGridName}`;
    const rawSettings = localStorage.getItem(cacheKey);
    const dataGridSettings = rawSettings ? JSON.parse(rawSettings) : null;

    const [settings, setSettings] = useState(dataGridSettings);

    const setAndSaveSettings = useCallback((key, value) => {
        setSettings(currentSettings => {
            const newSettings = {
                ...(currentSettings ?? {}),
                [key]: value,
            };
            localStorage.setItem(cacheKey, JSON.stringify(newSettings));
            return newSettings;
        });
    }, [cacheKey]);

    return [settings, setAndSaveSettings];
}