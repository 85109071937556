import React from "react";

import {mainContentContainerClass} from "other/Helper";

/**
 * @typedef {{addRoomForHelpWidget?: boolean}} MainContentProps
 * @param {React.PropsWithChildren<MainContentProps>} props
 * @returns 
 */
export function MainContent({addRoomForHelpWidget, children, ...props}) {
    const classNames = [mainContentContainerClass()];
    if (addRoomForHelpWidget) {
        classNames.push('room-for-help-widget');
    }

    return (
        <div className={classNames.join(' ')} {...props}>
            {children}
        </div>
    )
}

export function PageHead({children, ...props}) {
    return (
        <div id="page-head" className="no-padding-mobile" {...props}>
            {children}
        </div>
    )
}

export function PageHeader({title, subTitle, action, children, ...props}) {
    return (
        <div className="nav-header-panel no-padding-print">
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <div>
                    <h3
                        className={"section-titles admin-page-header"}
                        style={{display: "inline-block", margin: "0px"}}
                    >
                        {title}
                    </h3>
                    { subTitle &&
                        <p 
                            className="header zero-dark-grey"
                            style={{marginBottom: "0px", paddingLeft: "2px"}}
                        >
                            {subTitle}
                        </p>
                    }
                </div>
                {action ?? null}
            </div>
            {children ?? null}
        </div>
    )
}

export function PageContent({children, noTopPadding = false, style = {}, ...props}) {
    const defaultStyle = {...style}

    if (noTopPadding) {
        defaultStyle.paddingTop = 0;
    }

    return (
        <div id="page-content" className="no-padding-mobile" style={defaultStyle} {...props}>
            {children}
        </div>
    )
}

export function BorderedPanel({children, noPadding = false, topMargin = false, ...props}) {
    const classNames = [
        'panel',
        'panel-border'
    ];

    if (!noPadding) {
        classNames.push('panel-zero');
    } else {
        classNames.push('mar-btm-0');
    }

    if (topMargin) {
        classNames.push('panel-top-margin');
    }

    if (props.className) {
        classNames.push(...props.className.split(' '));
        delete props.className;
    }


    return (
        <div className={classNames.join(' ')} {...props}>
            {children}
        </div>
    )
}
