// @ts-check

import { DatePicker, Input, Modal, Select } from "antd";
import Button from "components/Shared/Button";
import FormGroup from "components/Shared/Forms/FormGroup";
import MultiUserSelect from "components/Shared/MultiUserSelect";
import { useMemo, useState } from "react";
import { TASK_STATUS_OPTIONS } from "./tasks";
import { isEmpty } from "lodash-es";
import { tasksApi } from "api/zero-api";
import dayjs from "dayjs";
import NotificationAlert from "other/NotificationAlert";
import { getErrorMessageFromResponse } from "other/Helper";

function getInitialTaskFormValues(task) {
    return {
        title: task?.title ?? "",
        status: task?.status ?? "open",
        dueDate: task?.due_date ? dayjs(task.due_date) : null,
        assignedUsers: task?.assigned_users ?? [],
    }
}


/**
 * @param {DirectoryUser[]} users 
 * @returns {DirectoryUser[]}
 */
function excludeViewersFilter(users) {
    return users.filter(user => user.role !== 'viewer');
}

/**
 * @param {{
 *  task?: Task,
 *  onClose: () => void,
 *  onMutation?: (task: Task) => void,
 *  submissionId?: string,
 *  fieldId?: string
 * }} props 
 */
export default function TaskDetailModal({task, onClose, onMutation, submissionId=null, fieldId=null}) {
    const isCreationModal = !task;

    const initialValues = useMemo(() => getInitialTaskFormValues(task), [task]);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(/** @type {{title?: string, assignedUsers?: string}} */({}));
    const [title, setTitle] = useState(initialValues.title);
    const [status, setStatus] = useState(initialValues.status);
    const [dueDate, setDueDate] = useState(initialValues.dueDate);
    const [assignedUsers, setAssignedUsers] = useState(initialValues.assignedUsers);

    const validateInput = () => {
        const newErrors = {};

        if (!title) {
            newErrors['title'] = 'Field is required.';
        }

        if (assignedUsers.length === 0) {
            newErrors['assignedUsers'] = 'At least one user must be assigned.';
        }

        setErrors(newErrors);
        return isEmpty(newErrors);
    }

    const upsertTask = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            const body = {
                title,
                status,
                due_date: dueDate?.format("YYYY-MM-DD") ?? null,
                assigned_user_uuids: assignedUsers.map(user => user.uuid),
                submission_uuid: submissionId ?? undefined,
                submission_field_uuid: fieldId ?? undefined,
            }

            setLoading(true);
            let apiCall = task ? tasksApi.partialUpdate(task.uuid, body) : tasksApi.create(body);
            const res = await apiCall;
            const updatedTask = await res.json();
            onMutation?.(updatedTask);
            onClose();
        } catch (err) {
            NotificationAlert('error', '', `Could not ${task ? 'update' : 'create'} todo.`);
            const message = await getErrorMessageFromResponse(err, 'Unknown error while upserting task');
            console.error(message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            title={`${isCreationModal ? 'Create' : 'Edit'} To-Do`}
            open
            maskClosable={false}
            maskTransitionName="maskTransitionName"
            onCancel={onClose}
            footer={
                <div>
                    <Button type="discard" onClick={onClose}>Cancel</Button>
                    <Button type="primary" onClick={upsertTask} loading={loading}>{isCreationModal ? 'Create' : 'Save'}</Button>
                </div>
            }
        >
            <div>
                <FormGroup required label="Title" error={errors.title}>
                    <Input
                        placeholder="Enter a title..."
                        value={title}
                        onChange={(ev) => setTitle(ev.target.value)}
                    />
                </FormGroup>
                <FormGroup required label="Status">
                    <Select 
                        options={TASK_STATUS_OPTIONS}
                        style={{width: '50%'}}
                        dropdownStyle={{zIndex: "1200"}}
                        placeholder="Select a status"
                        value={status}
                        onChange={(newStatus) => setStatus(newStatus)}
                    />
                </FormGroup>
                <FormGroup label="Due Date">
                    <DatePicker 
                        style={{width: '50%'}}
                        value={dueDate}
                        format={"MMM D, YYYY"}
                        onChange={(date) => {
                            console.log('>>> date:', date); // DEBUG REMOVEME
                            setDueDate(date);
                        }}
                    />
                </FormGroup>
                <FormGroup required label="Assignees" error={errors.assignedUsers}>
                    <MultiUserSelect
                        selectedUsers={assignedUsers}
                        onChange={setAssignedUsers}
                        userFilter={excludeViewersFilter}
                    />
                </FormGroup>
            </div>
        </Modal>
    );
}