import { reportError } from 'other/errorReporting';
import {isIE, isProduction, generateUUID, safe_get, joinTeamsAndMembers} from '../other/Helper';
import NotificationAlert from '../other/NotificationAlert';

var abortController;
export {abortController};


var CONFIG = require('../other/Config');

var team_cookie = "";
var organization_uuid = "";
var session_uuid = "";
var kiosk_mode_header = isAuthTokenForKiosk() ? "yes": "no";

function isLocalServer() {
    return process.env.REACT_APP_LOCAL_SERVER === '1' && window.location.hostname === "localhost";
}

// SOURCED FROM: https://www.w3schools.com/js/js_cookies.asp
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    exdays = 30;
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function stringify(body) {
    if (typeof (body) === 'string') return body;

    return JSON.stringify(body);
}

// https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

function updateAuthToken(updated_token) {
    if (updated_token !== undefined && updated_token !== "") {
        setCookie(getAuthCookieName(), updated_token, 30);
    } else {
        setCookie(getAuthCookieName(), "does-not-exist", 0);
    }

};

function getAuthCookieName() {
    return isLocalServer() ? "AuthorizationLocal" : "Authorization"
}

function getAuthToken() {
    return getCookie(getAuthCookieName());
}

export function isLoggedIn() {
    const token = getAuthToken();
    if (!token || token === 'does-not-exist') {
        return false;
    }

    if (isAuthTokenForKiosk()) {
        return false;
    }

    return true;
}

export function isAuthTokenForKiosk() {
    try {
        const token = getAuthToken();
        const claims = jwtDecode(token);
        return claims['kiosk_session'] === true;
    } catch (err) {
        return false;
    }
}

function jwtDecode(token) {
    try {
        // From https://stackoverflow.com/a/38552302/11874761
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (err) {
        throw new Error("Could not decode JWT");
    }
}

function getMobileEnabledCookie() {
    // return cookies.get('enabled-mobile');
    return getCookie("enabled-mobile");
}

export function getDontSetTimezoneCookie() {
    return getCookie('DontSetTimezone');
}

function isMobileApp() {
    var mobile_cookie = getMobileEnabledCookie()
    return mobile_cookie !== undefined && mobile_cookie !== "" && mobile_cookie !== null;
}

function updateTeamCookie(updated_cookie) {
    team_cookie = updated_cookie;
}

function updateOrganizationUUID(uuid) {
    // if (uuid !== "") {
    //     setCookie("Organization", uuid, 30);
    // }
    // else {
    //     setCookie("Organization", "", 0);
    // }
    organization_uuid = uuid;
}

function updateSessionUUID(uuid) {
    session_uuid = uuid;
}

function updateKioskModeHeader(value) {
    if (value) {
        kiosk_mode_header = "yes"
    } else {
        kiosk_mode_header = "no"
    }
}

function updateRedirectRoute(route) {
    setCookie("RedirectRoute", route, 1)
}



function APIConfiguration() {
};

APIConfiguration.BASE_API_URL = CONFIG.BASE_API_URL();
APIConfiguration.BASE_VERSION = '/v1';

/** @type {ApiConfiguration} */
const privateApiConfig = {
    baseUrl: CONFIG.BASE_API_URL(),
    version: '/v1',
}

/** @type {ApiConfiguration} */
const publicApiConfig = {
    baseUrl: `${CONFIG.BASE_API_URL()}/public`,
    version: '/v1',
}

function APIRoutes() {
};

// AUTHENTICATION & USER
APIRoutes.LOGOUT = '/logout';
APIRoutes.LOGIN = '/auth';
APIRoutes.OAUTH_LOGIN = '/auth_token'
APIRoutes.REGISTER = '/registration/register_email';
APIRoutes.CREATE_ADMIN = '/registration/create_admin';
APIRoutes.CREATE_USER = '/registration/create_user';

APIRoutes.FILE_UPLOAD_USERS = "/"

APIRoutes.CURRENT_USER = function () {
    return '/users/current';
}
APIRoutes.UPDATE_USER = function (uuid) {
    return `/users/${uuid}`;
};
APIRoutes.UPDATE_USER_FORCE = function (uuid) {
    return `/users/${uuid}?force=1`;
};
APIRoutes.GET_USER = function (uuid, query) {
    return `/users/${uuid}${query}`;
};
APIRoutes.RESEND_INVITE = function (team_uuid, user_uuid) {
    return `/teams/${team_uuid}/resend_invite/${user_uuid}`;
};
APIRoutes.DEACTIVATE_USER = function (user_uuid) {
    return `/organizations/current/users/${user_uuid}/deactivate`;
};
APIRoutes.ACTIVATE_USER = function (user_uuid) {
    return `/organizations/current/users/${user_uuid}/activate`;
};
APIRoutes.GET_DEACTIVATED_USERS = function () {
    return `/organizations/current/deactivated_users`;
};
APIRoutes.DELETE_PENDING_USER = function (uuid) {
    return `/organizations/current/users/${uuid}/delete`;
};
APIRoutes.GET_LIST_USERS = function (query) {
    return `/users${query}`;
}

APIRoutes.REQUEST_PW_RESTORE = '/request_password_restore';
APIRoutes.PASSWORD_RESTORE = '/password_restore';
APIRoutes.UPDATE_USER_PASSWORD = function (uuid) {
    return `/users/${uuid}/update_password`;
};
APIRoutes.UPDATE_USER_PASSWORD_ADMIN = function (uuid) {
    return `/users/${uuid}/update_password_admin`;
};


APIRoutes.REMOVE_ROLE = function (uuid) {
    return `/users/${uuid}/remove_role`;
};
APIRoutes.ADD_ROLE = function (uuid) {
    return `/users/${uuid}/add_role`;
};
APIRoutes.SET_ROLE = function (uuid) {
    return `/users/${uuid}/set_role`;
};


APIRoutes.SESSIONS = function () {
    return '/users/current/sessions'
}
APIRoutes.END_SESSION = function (uuid) {
    return `/users/current/sessions/${uuid}`;
}

APIRoutes.GET_TIMEZONES = function () {
    return '/users/timezones';
};

APIRoutes.GET_INITIAL_DATA = function () {
    return '/initial_data'
}


// LIBRARY
APIRoutes.SHARED_LIBRARY = function (query) {
    return `/shared_library${query}`
};
APIRoutes.GET_SHARED_ARTICLE = function (uuid) {
    return `/shared_library/${uuid}`
};

APIRoutes.LIBRARY = '/library';
APIRoutes.MAKE_ARTICLE = '/library'
APIRoutes.GET_ARTICLE = function (uuid) {
    return `/library/${uuid}`
};
APIRoutes.UPDATE_ARTICLE = function (uuid) {
    return `/library/${uuid}`
};
APIRoutes.DELETE_ARTICLE = function (uuid) {
    return `/library/${uuid}`
};

APIRoutes.LIBRARY_RENAME_FILE = function (uuid) {
    return `/library/rename_attachment/${uuid}`
}

APIRoutes.CATEGORIES = '/library/tags';
APIRoutes.ARTICLE_ACTIVITIES = function (uuid) {
    return `/library/${uuid}/activities`
};
APIRoutes.GET_ARTICLE_VIEWS = function (uuid) {
    return `/library/${uuid}/viewers`
};

APIRoutes.GET_ARTICLE_VERSION = function (articleUUID, versionUUID) {
    return `/library/${articleUUID}/version/${versionUUID}`
};
APIRoutes.REVERT_ARTICLE_VERSION = function (articleUUID, versionUUID) {
    return `/library/${articleUUID}/version/${versionUUID}`
};

// BULLETIN BOARD
APIRoutes.BULLETIN_TAGS = function (query) {
    return `/bulletin_board/tags/${query}`;
};
APIRoutes.BULLETIN_CATEGORIES = function (query) {
    return `/bulletin_board/categories/${query}`;
};
APIRoutes.LOCATION_OPTIONS = function (query) {
    return `/bulletin_board/location_options${query}`;
};
APIRoutes.UPDATE_TAGS = '/bulletin_board/tags'
APIRoutes.UPDATE_CATEGORIES = '/bulletin_board/categories';
APIRoutes.CREATE_TAG = '/bulletin_board/tags'
APIRoutes.MAKE_BULLETIN = '/bulletin_board';

APIRoutes.GET_POST_DRAFTS = function (query) {
    return `/bulletin_board/my_drafts${query}`
}
APIRoutes.GET_BULLETIN_POST = function (uuid) {
    return `/bulletin_board/${uuid}`
};
APIRoutes.UPDATE_BULLETIN = function (uuid) {
    return `/bulletin_board/${uuid}`
};
APIRoutes.LIST_OF_BULLETINS = function (query) {
    return `/bulletin_board${query}`
};
APIRoutes.FILTER_OPTIONS = function () {
    return `/bulletin_board/filter_options`
};
APIRoutes.POST_VIEWERS = function (uuid) {
    return `/bulletin_board/${uuid}/viewers`;
};
APIRoutes.DELETE_POST = function (uuid) {
    return `/bulletin_board/${uuid}`
};
APIRoutes.SEVERITY = function (query) {
    return `/bulletin_board/severity_levels${query}`
};
APIRoutes.UPDATE_SEVERITY = function () {
    return `/bulletin_board/severity_levels`
};
APIRoutes.GET_SUB_STATUS = function (query) {
    return `/bulletin_board/sub_statuses${query}`;
};
APIRoutes.UPDATE_SUB_STATUS = function () {
    return `/bulletin_board/sub_statuses`
};

APIRoutes.REOPEN_POST = function (uuid) {
    return `/bulletin_board/${uuid}/reopen`;
};
APIRoutes.CLOSE_POST = function (uuid) {
    return `/bulletin_board/${uuid}/close`;
};
APIRoutes.SHARE_POST = function (uuid) {
    return `/bulletin_board/${uuid}/share`;
};
APIRoutes.SHARE_OPTIONS = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/share_options`;
}
APIRoutes.MOVE_POST = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/move`
}
APIRoutes.COPY_POST = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/copy`
}
APIRoutes.EXPORT_POST = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/export`
}
APIRoutes.DUE_DATE = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/due_date`
}
APIRoutes.ADD_VIEW = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/add_view`
}

APIRoutes.ADD_REACTION = function (post_uuid, emoji) {
    return `/bulletin_board/${post_uuid}/reactions/${emoji}`
}
APIRoutes.DELETE_REACTION = function (post_uuid, emoji) {
    return `/bulletin_board/${post_uuid}/reactions/${emoji}`
}

APIRoutes.GET_COMMENTS = function (uuid) {
    return `/bulletin_board/${uuid}/comments`;
};
APIRoutes.ADD_COMMENT = function (uuid) {
    return `/bulletin_board/${uuid}/comments`;
};
APIRoutes.EDIT_COMMENT = function (post_uuid, comment_uuid) {
    return `/bulletin_board/${post_uuid}/comments/${comment_uuid}`
}
APIRoutes.DELETE_COMMENT = function (post_uuid, comment_uuid) {
    return `/bulletin_board/${post_uuid}/comments/${comment_uuid}`;
};

APIRoutes.ADD_REACTION_COMMENT = function (post_uuid, comment_uuid, emoji) {
    return `/bulletin_board/${post_uuid}/comments/${comment_uuid}/reactions/${emoji}`
}
APIRoutes.DELETE_REACTION_COMMENT = function (post_uuid, comment_uuid, emoji) {
    return `/bulletin_board/${post_uuid}/comments/${comment_uuid}/reactions/${emoji}`
}

APIRoutes.SUBSCRIBE_USER_OPTIONS = function () {
    return `/bulletin_board/subscribe_users_options`
}
APIRoutes.POST_SUBSCRIBERS = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/subscribers`
}
APIRoutes.SUBSCRIBE_USERS = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/subscribe_users`
}
APIRoutes.UNSUBSCRIBE_USERS = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/unsubscribe_users`
}

APIRoutes.GET_TEMPLATES = function (query) {
    return `/bulletin_board/templates`;
}
APIRoutes.CREATE_TEMPLATE = function () {
    return `/bulletin_board/templates`;
}
APIRoutes.UPDATE_TEMPLATE = function (template_uuid) {
    return `/bulletin_board/templates/${template_uuid}`;
}
APIRoutes.GET_TEMPLATE = function (template_uuid) {
    return `/bulletin_board/templates/${template_uuid}`
}
APIRoutes.DELETE_TEMPLATE = function (template_uuid) {
    return `/bulletin_board/templates/${template_uuid}`
}

APIRoutes.MY_ASSIGNMENTS = function () {
    return `/bulletin_board/my_assignments`
};
APIRoutes.ASSIGN_USER = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/assign_users`
};
APIRoutes.UNASSIGN_USER = function (post_uuid) {
    return `/bulletin_board/${post_uuid}/unassign_users`
};
APIRoutes.GET_ASSIGN_USERS = function () {
    return `/bulletin_board/assign_users_options`
};

APIRoutes.FEED_ANALYTICS_EXPORT = '/bulletin_board/feed_export';

// DASHBOARD
APIRoutes.PANELS_INFO = '/dashboard/panels_info';
APIRoutes.DASHBOARD_INFO = "/latest_activity"
APIRoutes.GET_OPEN_ASSIGNMENTS = function (query) {
    return `/assignments/open${query}`
}
APIRoutes.GET_CLOSED_ASSIGNMENTS = function (query) {
    return `/assignments/closed${query}`
}

// TEAMS
APIRoutes.CREATE_TEAM = '/teams';
APIRoutes.GET_TEAM = function (uuid) {
    return `/teams/${uuid}`
};
APIRoutes.UPDATE_TEAM = function (uuid) {
    return `/teams/${uuid}`
};
APIRoutes.DELETE_TEAM = function (uuid) {
    return `/teams/${uuid}`
};
APIRoutes.LIST_TEAMS = '/teams';
APIRoutes.GET_MY_TEAMS_DATA = "/teams/my_teams"
APIRoutes.USERS_IN_TEAM = function (uuid, query) {
    return `/teams/${uuid}/users${query}`
};
APIRoutes.CHANGE_USER_TEAMS = '/teams/update_user_teams';
APIRoutes.INVITE_USERS = function (uuid) {
    return `/teams/${uuid}/invite`
};
APIRoutes.MASS_INVITE_WARNINGS = function () {
    return `/teams/mass_invite_warnings`
};
APIRoutes.MASS_INVITE = function () {
    return `/teams/mass_invite`
};
APIRoutes.ADD_USERS = function (uuid) {
    return `/teams/${uuid}/add_users`
};
APIRoutes.REMOVE_USER = function (uuid) {
    return `/teams/${uuid}/remove_user`
};

APIRoutes.REGISTER_USER_LINK = '/teams/create_user';

// ORGRANIZATION
APIRoutes.GET_ORGANIZATION = "/organizations";
APIRoutes.UPDATE_ORGANIZATION = "/organizations/current";
APIRoutes.CREATE_ORGANIZATION = "/organizations";
APIRoutes.TOGGLE_INCIDENTS_ENABLED = "/organizations/current/incidents_enabled_toggle"
APIRoutes.TOGGLE_RISKLEVEL_ENABLED = "/organizations/current/risk_level_enabled_toggle"
APIRoutes.TOGGLE_POST_DUE_DATE_ENABLED = "/organizations/current/post_due_date_enabled_toggle"
APIRoutes.TOGGLE_LOCATION_ENABLED = "/organizations/current/post_location_enabled_toggle"
APIRoutes.UPDATE_CUSTOM_POST_FIELD = "/organizations/current/update_custom_post_field"
APIRoutes.UPDATE_POST_FIELD_NAMES = "/organizations/post_field_names"

APIRoutes.TOGGLE_FEATURE_FLAG = function (flag_name) {
    return `/organizations/feature_flags/${flag_name}`;
}

// ANALYTICS
APIRoutes.ANALYTICS_CATEGORIES_CHART = function (query) {
    return `/analytics/bulletin_board/categories_chart${query}`
};
APIRoutes.ANALYTICS_SEVERITY_LEVEL = function (query) {
    return `/analytics/bulletin_board/severity_level${query}`;
};
APIRoutes.ANALYTICS_POPULAR_KEYWORDS = function (query) {
    return `/analytics/bulletin_board/popular_keywords${query}`;
};
APIRoutes.ANALYTICS_BULLETIN_TAGS = function (query) {
    return `/analytics/bulletin_board/tags${query}`;
};
APIRoutes.ANALYTICS_SUB_STATUS = function (query) {
    return `/analytics/bulletin_board/sub_statuses${query}`;
};
APIRoutes.ANALYTICS_BULLETIN_CATEGORIES = function (query) {
    return `/analytics/bulletin_board/categories${query}`
}
APIRoutes.ANALYTICS_LEADING_INDICATORS = function (query) {
    return `/analytics/bulletin_board/leading_indicators${query}`;
};
APIRoutes.ANALYTICS_FEED_ACTIVITY = function (query) {
    return `/analytics/bulletin_board/activity${query}`;
};
APIRoutes.ANALYTICS_USERS_CHART = function (query) {
    return `/analytics/users/chart${query}`;
};
APIRoutes.ANALYTICS_USERS_PANEL = function (query) {
    return `/analytics/users/panel_info${query}`;
};
APIRoutes.ANALYTICS_TOP_POSTERS = function (query) {
    return `/analytics/top_posters${query}`;
};
APIRoutes.ANALYTICS_MEMBERS = function (query) {
    return `/analytics/members${query}`;
};
APIRoutes.ANALYTICS_USER = function (uuid, query) {
    return `/analytics/users/${uuid}${query}`;
};

// INCIDENTS
APIRoutes.CREATE_LOCATION = function () {
    return `/incident_reporting/locations`;
}
APIRoutes.GET_LOCATION = function (uuid) {
    return `/incident_reporting/locations/${uuid}`;
}
APIRoutes.GET_LOCATIONS = function (query) {
    return `/incident_reporting/locations${query}`;
}
APIRoutes.UPDATE_LOCATION = function (uuid) {
    return `/incident_reporting/locations/${uuid}`;
}
APIRoutes.DELETE_LOCATION = function (uuid) {
    return `/incident_reporting/locations/${uuid}`;
}
APIRoutes.CREATE_INCIDENT = function () {
    return `/incident_reporting/incidents`;
}
APIRoutes.GET_INCIDENT = function (uuid) {
    return `/incident_reporting/incidents/${uuid} `
}
APIRoutes.GET_INCIDENTS = function (query) {
    return `/incident_reporting/incidents${query} `
}
APIRoutes.GET_INCIDENT_DRAFTS = function () {
    return `/incident_reporting/my_drafts`
}
APIRoutes.UPDATE_INCIDENT = function (uuid) {
    return `/incident_reporting/incidents/${uuid} `
}
APIRoutes.DELETE_INCIDENT = function (uuid) {
    return `/incident_reporting/incidents/${uuid} `
}
APIRoutes.GET_INCIDENT_OPTIONS = function (query) {
    return `/incident_reporting/incidents/options${query}`;
}
APIRoutes.CREATE_CORRECTIVE_ACTION = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/corrective_actions`
}
APIRoutes.UPDATE_CORRECTIVE_ACTION = function (incident_uuid, action_uuid) {
    return `/incident_reporting/incidents/${incident_uuid}/corrective_actions/${action_uuid}`
}
APIRoutes.DELETE_CORRECTIVE_ACTION = function (incident_uuid, action_uuid) {
    return `/incident_reporting/incidents/${incident_uuid}/corrective_actions/${action_uuid}`
}
APIRoutes.GET_CORRECTIVE_ACTIONS = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/corrective_actions`
}
APIRoutes.MAKE_RELATED_POST = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/post`
}
APIRoutes.MOVE_INCIDENT = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/change_location`
}
APIRoutes.INCIDENT_EXPORT = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/export_pdf`
}
APIRoutes.INCIDENT_EXPORT_XLS = function (uuid) {
    return `/incident_reporting/incidents/${uuid}/export_xls`
}
APIRoutes.INCIDENTS_REPORT = function () {
    return `/incident_reporting/exports`
}
APIRoutes.INCIDENTS_REPORT_HISTORY = function () {
    return `/incident_reporting/exports/history`
}
APIRoutes.GET_EVENT_TYPES = function (query) {
    return `/incident_reporting/event_types${query}`;
}
APIRoutes.GET_INCIDENT_CAUSES = function (query) {
    return `/incident_reporting/incident_causes${query}`
}
APIRoutes.INCIDENT_INJURIES = function (query) {
    return `/incident_reporting/incident_injuries${query}`
}
APIRoutes.INCIDENT_FORM_SUBMISSION = function (incident_uuid) {
    return `/incident_reporting/incidents/${incident_uuid}/submissions`
}
APIRoutes.INCIDENT_RENAME_FILE = function (uuid) {
    return `/incident_reporting/rename_attachment/${uuid}`
}

// INCIDENTS ANALYTICS 
APIRoutes.ILLNESS_INFO_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/illness_info${query}`;
}
APIRoutes.INJURY_TYPE_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/injury_info${query}`;
}
APIRoutes.BODY_PARTS_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/body_parts_info${query}`
}
APIRoutes.BODY_FRONT_PARTS_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/body_front_parts_info${query}`;
}
APIRoutes.BODY_BACK_PARTS_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/body_back_parts_info${query}`;
}
APIRoutes.INCIDENT_DATE_CHART = function (query) {
    return `/incident_reporting/analytics/incident_date_chart${query}`;
}
APIRoutes.INCIDENT_TOTALS_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/totals_info${query}`;
}
APIRoutes.INCIDENT_CAUSES_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/incident_cause_info${query}`;
}
APIRoutes.EVENT_TYPE_ANALYTICS = function (query) {
    return `/incident_reporting/analytics/event_types_info${query}`;
}
APIRoutes.INCIDENT_ANALYTICS_EXPORT = '/incident_reporting/analytics_export';
APIRoutes.INCIDENT_BODY_CHART_EXPORT = '/incident_reporting/body_chart_export';

// FORMS
APIRoutes.GET_FORMS = function (query) {
    return `/forms${query}`
}
APIRoutes.CREATE_FORM = function () {
    return '/forms'
}
APIRoutes.GET_FORM = function (uuid) {
    return `/forms/${uuid}`
}
APIRoutes.UPDATE_FORM = function (uuid) {
    return `/forms/${uuid}`
}
APIRoutes.DELETE_FORM = function (uuid) {
    return `/forms/${uuid}`
}
APIRoutes.MAKE_FORM_COPY = function (uuid) {
    return `/forms/${uuid}/make_copy`
}
APIRoutes.GET_TEAM_SUBMISSIONS = function (query) {
    return `/forms/submissions${query}`;
}
APIRoutes.GET_FORM_SUBMISSIONS = function (query) {
    return `/forms/submissions${query}`;
}
APIRoutes.GET_FORM_SUBMISSIONS2 = function (query) {
    return `/forms/individual_submissions${query}`;
}
APIRoutes.CREATE_SUBMISSION = function (uuid) {
    return `/forms/submissions`;
}
APIRoutes.GET_SUBMISSION = function (sub_uuid) {
    return `/forms/submissions/${sub_uuid}`;
}
APIRoutes.UPDATE_SUBMISSION = function (sub_uuid) {
    return `/forms/submissions/${sub_uuid}`;
}
APIRoutes.EXPORT_SUBMISSION = function (sub_uuid) {
    return `/forms/submissions/${sub_uuid}/export`;
}
APIRoutes.LINK_POST_TO_FIELD = function (sub_uuid) {
    return `/forms/submissions/${sub_uuid}/link_post_to_field`;
}
APIRoutes.GET_FORM_DRAFTS = function (query) {
    return `/forms/my_drafts${query}`
}


APIRoutes.DELETE_SUBMISSION = function (sub_uuid) {
    return `/forms/submissions/${sub_uuid}`;
}
APIRoutes.SHARE_FORM_OPTIONS = function (uuid) {
    return `/forms/${uuid}/share_options`;
}
APIRoutes.SHARE_FORM = function (uuid) {
    return `/forms/${uuid}/share`;
}
APIRoutes.COPY_TO_POST = function () {
    return "/forms/submissions/make_a_post"
}

APIRoutes.GET_FORM_TEMPLATES = function (query) {
    return `/forms/pre_made${query}`
}
APIRoutes.CREATE_PREMADE_FORM = function (uuid) {
    return `/forms/pre_made/${uuid}`
}

APIRoutes.FORM_ANALYTICS = function (query) {
    return `/forms/analytics${query}`;
}
APIRoutes.FORM_ANALYTICS_FIELDS = function (query) {
    return `/forms/analytics_fields${query}`
}
APIRoutes.FORMS_CHART = function (query) {
    return `/forms/analytics_chart${query}`
}

APIRoutes.MY_FORM_ASSIGNMENTS = function (query) {
    return `/forms/my_assignments${query}`
}

APIRoutes.FORM_REPORT_DATA = function (query) {
    return `/forms/submissions/report_data${query}`
}

APIRoutes.FEED_REPORT_DATA = function (query) {
    return `/feed/submissions/report_data${query}`
}

APIRoutes.GET_FORMS_FILTERS = function (query) {
    return `/forms/filter_options${query || ''}`
}
APIRoutes.GET_FORM_CATEGORIES = function () {
    return `/forms/categories`
};

APIRoutes.FORM_ANALYTICS_EXPORT = '/forms/analytics_export';

APIRoutes.COURSES_ANALYTICS_EXPORT = '/forms/analytics_export_courses';

// FORM SCHEDULER
APIRoutes.SCHEDULES = function (query) {
    return `/forms/schedulers${query}`
}
APIRoutes.CREATE_SCHEDULE = function () {
    return `/forms/schedulers/`
}
APIRoutes.GET_SCHEDULE = function (uuid) {
    return `/forms/schedulers/${uuid}`
}
APIRoutes.GET_SCHEDULE_IS_LOCKED = function (uuid) {
    return `/forms/schedulers/${uuid}/is_locked`
}
APIRoutes.UPDATE_SCHEDULE = function (uuid) {
    return `/forms/schedulers/${uuid}`
}
APIRoutes.ARCHIVE_SCHEDULE = function (uuid) {
    return `/forms/schedulers/${uuid}`
}
APIRoutes.SCHEDULE_ANALYTICS = function (uuid, query) {
    return `/forms/schedulers/${uuid}/analytics${query}`
}
APIRoutes.UPDATE_SCHEDULE_SUBS = function (uuid) {
    return `/forms/schedulers/${uuid}/update_subscribers`
}

// FORM ASSIGNMENT
APIRoutes.GET_ASSIGNMENT = function (uuid) {
    return `/forms/assignments/${uuid}`
}
APIRoutes.PATCH_ASSIGNMENT = function (uuid) {
    return `/forms/assignments/${uuid}`
}
APIRoutes.DELETE_ASSIGNMENT = function (uuid) {
    return `/forms/assignments/${uuid}`
}
APIRoutes.ASSIGNMENT_FILTERS = function (query) {
    return `/forms/assignments/filters${query}`
}
APIRoutes.ASSIGNMENTS_ANALYTICS = function (query) {
    return `/forms/assignments/analytics${query}`
}
APIRoutes.ALL_FORM_ASSIGNMENTS = function (query) {
    return `/forms/assignments${query}`
}

// NOTIFICATIONS
APIRoutes.GET_NOTIFICATIONS = function (query) {
    return `/notifications${query}`;
};
APIRoutes.NOFITICATIONS_MARK_ALL_AS_READ = function () {
    return '/notifications/mark_all_as_read';
};
APIRoutes.NOFITICATION_MARK_READ = function (uuid) {
    return `/notifications/${uuid}/mark_as_read`;
};
APIRoutes.SUBSCRIBE_PUSH_NOTIFICATIONS = function () {
    return "/notifications/pn/subscribe";
}
APIRoutes.UNSUBSCRIBE_PUSH_NOTIFICATIONS = function () {
    return "/notifications/pn/unsubscribe";
}
APIRoutes.TEST_SMS_NOTIFICATIONS = function () {
    return "/notifications/sms/test/";
}
APIRoutes.TEST_PUSH_NOTIFICATIONS = function () {
    return "/notifications/pn/test";
}
APIRoutes.TEST_PUSH_NOTIFICATIONS_DEV = function () {
    return "/notifications/pn/test_url";
}
APIRoutes.TEST_PUSH_NOTIFICATION_JS_DEV = function () {
    return "/notifications/pn/test_js";
}
APIRoutes.UPDATE_NOTIFICATION_SETTINGS = function () {
    return "/notifications/settings";
}
APIRoutes.GET_NOTIFICATION_SETTINGS = function () {
    return "/notifications/settings"
}
APIRoutes.GET_ALL_BROWSER_NOTIFICATION_SETTINGS = function () {
    return "/notifications/all_browser_settings"
}

// ALERTS
APIRoutes.GET_ALL_ALERTS = function () {
    return `/alerts/generic`
}
APIRoutes.GET_ALERT = function (uuid) {
    return `/alerts/generic/${uuid}`
}

APIRoutes.GET_FORM_SUB_ALERTS = function () {
    return `/alerts/form_submission`
}
APIRoutes.GET_FORM_SUB_ALERT = function (uuid) {
    return `/alerts/form_submission/${uuid}`
}
APIRoutes.CREATE_FORM_SUB_ALERT = function () {
    return `/alerts/form_submission`
}
APIRoutes.PATCH_FORM_SUB_ALERT = function (uuid) {
    return `/alerts/form_submission/${uuid}`
}
APIRoutes.DELETE_FORM_SUB_ALERT = function (uuid) {
    return `/alerts/form_submission/${uuid}`
}
APIRoutes.GET_FORM_SUB_ALERT_OPTIONS = function () {
    return `/alerts/form_submission/options`
}

APIRoutes.GET_INCIDENT_ALERTS = function () {
    return `/alerts/new_incident`
}
APIRoutes.GET_INCIDENT_ALERT = function (uuid) {
    return `/alerts/new_incident/${uuid}`
}
APIRoutes.CREATE_INCIDENT_ALERT = function () {
    return `/alerts/new_incident`
}
APIRoutes.PATCH_INCIDENT_ALERT = function (uuid) {
    return `/alerts/new_incident/${uuid}`
}
APIRoutes.DELETE_INCIDENT_ALERT = function (uuid) {
    return `/alerts/new_incident/${uuid}`
}
APIRoutes.GET_INCIDENT_ALERT_OPTIONS = function () {
    return `/alerts/new_incident/options`
}

// REPORTS
APIRoutes.ACCOUNT_REPORT = function () {
    return '/reports/account_report';
}
APIRoutes.DIRECTORY_REPORT = function () {
    return '/reports/directory_report';
}
APIRoutes.TEAM_REPORT = function () {
    return '/reports/teams_report';
}
APIRoutes.GET_REPORTS_HISTORY = function () {
    return '/reports/history';
}
APIRoutes.OPEN_POSTS_REPORT = function () {
    return '/reports/open_posts_report';
}
APIRoutes.FEED_POSTS_REPORT = function () {
    return '/reports/posts_export';
}
APIRoutes.FORM_REPORTS = function () {
    return "/reports/submission_report";
}
APIRoutes.FORM_REPORTS_HISTORY = function () {
    return "/reports/history/forms";
}

APIRoutes.FEED_REPORTS_HISTORY = function () {
    return "/reports/history/feed";
}

APIRoutes.SCHEDULE_REPORT_DATA = function (query) {
    return `/forms/schedulers/report_data${query}`
}
APIRoutes.GENERATE_SCHEDULE_REPORT = function () {
    return "/reports/schedule_report"
}

APIRoutes.GENERATE_ASSIGNMENT_REPORT = function () {
    return "/reports/assignment_report"
}

APIRoutes.CREATE_REPORT_SCHEDULE = function () {
    return `/reports/schedules`
};
APIRoutes.UPDATE_REPORT_SCHEDULE = function (uuid) {
    return `/reports/schedules/${uuid}`
};
APIRoutes.GET_REPORT_SCHEDULE_LIST = function () {
    return `/reports/schedules`
};

APIRoutes.COURSE_REPORT = function () {
    return "/reports/course_report"
}
APIRoutes.LMS_ASSIGNMENT_REPORT = function () {
    return "/reports/lms_assignment_report"
}
APIRoutes.LMS_SCHEDULE_REPORT = function () {
    return "/reports/lms_schedule_report"
}
APIRoutes.LMS_REPORT_HISTORY = function () {
    return "/reports/history/lms"
}

// MANAGE API KEYS
APIRoutes.GET_API_KEYS = function () {
    return "/organizations/api_keys"
}
APIRoutes.CREATE_API_KEY = function () {
    return "/organizations/api_keys"
}
APIRoutes.GET_API_KEY = function (uuid) {
    return `/organizations/api_keys/${uuid}`
}
APIRoutes.DELETE_API_KEY = function (uuid) {
    return `/organizations/api_keys/${uuid}`
}
APIRoutes.PATCH_API_KEY = function (uuid) {
    return `/organizations/api_keys/${uuid}`
}

// KIOSK MODE
APIRoutes.KIOSK_LOGIN = "/kiosk_mode/auth";
APIRoutes.KIOSK_TURN_ON = "/kiosk_mode/turn_on";
APIRoutes.KIOSK_TURN_OFF = function (uuid) {
    return `/kiosk_mode/sessions/${uuid}`
};
APIRoutes.KIOSK_RENAME_DEVICE = function (uuid) {
    return `/kiosk_mode/sessions/${uuid}`
};
APIRoutes.KIOSK_GET_SESSIONS = "/kiosk_mode/sessions";
APIRoutes.GET_KIOSK_USERS = "/kiosk_mode/users"

APIRoutes.CREATE_KIOSK_USER = '/organizations/current/create_user'

APIRoutes.GET_PRESIGNED_URL = "/users/presign_file_upload";

APIRoutes.GET_SHORT_LINK = function (linkId) {
    return `/links/${linkId}`
};

function createCrudApi(baseUrl, additionalActions = null) {
    let crudApi = {
        baseUrl,
        list(queryParams = '') {
            let url = baseUrl;

            if (queryParams instanceof URLSearchParams) {
                queryParams = queryParams.toString();
            }

            if (typeof(queryParams) === 'string' && queryParams.length > 0) {
                if (queryParams[0] !== '?') {
                    queryParams = '?' + queryParams;
                }
                url += queryParams;
            }

            return GETPromise(url);
        },
        retrieve(uuid) {
            return GETPromise(`${baseUrl}/${uuid}`);
        },
        create(body) {
            if (typeof(body) !== "string") {
                body = JSON.stringify(body);
            }
            return POSTPromise(baseUrl, body);
        },
        update(uuid, body) {
            if (typeof(body) !== "string") {
                body = JSON.stringify(body);
            }
            return PUTPromise(`${baseUrl}/${uuid}`, body);
        },
        partialUpdate(uuid, body) {
            if (typeof(body) !== "string") {
                body = JSON.stringify(body);
            }
            return PATCHPromise(`${baseUrl}/${uuid}`, body);
        },
        delete(uuid) {
            return DELETEPromise(`${baseUrl}/${uuid}`);
        }
    };

    if (additionalActions) {
        crudApi = {...crudApi, ...additionalActions};
    }

    return crudApi
}

function GETPromise(request, options = null) {
    return APIPromise(request, "GET", null, 'application/json', options);
}

function POSTPromise(request, body = null, content_type = 'application/json') {
    return APIPromise(request, "POST", body, content_type);
}

function PATCHPromise(request, body) {
    return APIPromise(request, "PATCH", body);
}

function PUTPromise(request, body) {
    return APIPromise(request, "PUT", body)
}

function DELETEPromise(request) {
    return APIPromise(request, "DELETE")
}

/**
 * @param {string} request 
 * @param {HttpMethod} method 
 * @param {*} body 
 * @param {string} content_type 
 * @param {*} options 
 * @returns {Promise<Response>}
 */
function APIPromise(request, method, body = null, content_type = 'application/json', options = null) {
    var headers = {
        'Accept': 'application/json',
        'Content-Type': content_type
    }

    headers["Authorization"] = getAuthToken();
    headers["Team"] = team_cookie;
    headers["Organization"] = organization_uuid;
    headers["KIOSK_MODE"] = kiosk_mode_header;
    headers["IS_APP"] = isMobileApp() ? "yes" : "no";

    headers["DEVICE_TYPE"] = getCookie("DEVICE_TYPE");
    headers["DEVICE_MODEL_NAME"] = getCookie("DEVICE_MODEL_NAME");
    headers["OS_NAME"] = getCookie("OS_NAME");
    headers["OS_VERSION"] = getCookie("OS_VERSION");
    // headers["DEVICE_NAME"]       = getCookie("DEVICE_NAME").replace(/'/g, "");   
    headers["DEVICE_UUID"] = getCookie("DEVICE_UUID");
    headers["APP_VERSION"] = getCookie("APP_VERSION");

    headers["Zero-Web-App-Version"] = process.env.REACT_APP_COMMIT_HASH ?? "unknown";

    let rejectOn403 = false;

    if (options) {
        if (options.rejectOn403) {
            rejectOn403 = true;
        }
    }

    return new Promise(function (resolve, reject) {
        CreateFetchRequest(request, method, headers, body, privateApiConfig, function (success) {
            if (success.ok) {
                resolve(success)
            } else {
                if (success.status === 401) {
                    success.json().then(success => {
                        var path = window.location.pathname;
                        if (isAuthTokenForKiosk()) {
                            window.location = "/kiosk_mode?redirect=" + path;
                        } else if (success.kiosk_mode_on) {
                            updateAuthToken(safe_get(success, "kiosk_mode_token", undefined));
                            updateKioskModeHeader(true)
                            window.location = "/kiosk_mode?redirect=" + path;
                        } else if (path !== "/" && !path.includes("/kiosk_mode") && !path.includes("/no_team")) {
                            window.location = "/login/redirect=" + path;
                        } else {
                            window.location = "/login?from_401=true";
                        }
                    });
                } else if (success.status === 403) {
                    if (rejectOn403) {
                        reject(success);
                    } else {
                        reportError(
                            "403 Response from API",
                            (err, data) => {
                                window.location.href = '/403';
                            },
                            {
                                currentPage: window.location.href,
                                apiRequest: {
                                    url: request,
                                    method,
                                    body
                                },
                            },
                        );
                    }
                } else {
                    reject(success)
                }
            }
        }, function (error) {
            reject(error)
        });

    });
}

/**
 * 
 * @param {string} url 
 * @param {HttpMethod} method 
 * @param {any} body 
 * @param {string} contentType 
 * @param {{}} options 
 * @returns {Promise<Response>}
 */
function PublicApiPromise(url, method, body = null, contentType = 'application/json', options = {}) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': contentType,
        'IS_APP': isMobileApp() ? "yes" : "no",

    };

    return new Promise(function (resolve, reject) {
        CreateFetchRequest(
            url,
            method,
            headers,
            body,
            publicApiConfig,
            response => {
                if (response.ok) {
                    resolve(response)
                } else {
                    reject(response);
                }
            },
            reject
        );

    });
}

/**
 * @param {string} url 
 * @param {{}} options 
 */
function PublicGetRequest(url, options = {}) {
    return PublicApiPromise(url, "GET", null, 'application/json', options);
}

/**
 * @param {string} url 
 * @param {any} body 
 * @param {string} contentType
 */
function PublicPostRequest(url, body = null, contentType = 'application/json') {
    if (contentType === 'application/json' && typeof(body) !== 'string') {
        body = JSON.stringify(body);
    }
    return PublicApiPromise(url, "POST", body, contentType);
}

/**
 * @param {string} url 
 * @param {any} body 
 * @param {string} contentType
 */
function PublicPutRequest(url, body = null, contentType = 'application/json') {
    if (contentType === 'application/json' && typeof(body) !== 'string') {
        body = JSON.stringify(body);
    }
    return PublicApiPromise(url, "PUT", body, contentType);
}

/**
 * 
 * @param {string} url 
 * @param {HttpMethod} method 
 * @param {*} headers 
 * @param {*} body 
 * @param {ApiConfiguration} apiConfig
 * @param {(res: Response) => any} success 
 * @param {(err: any) => any} error 
 */
function CreateFetchRequest(url, method, headers, body, apiConfig, success, error) {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const requestParams = {
        method: method,
        headers: headers,
        signal,
    }

    if (body != null) {
        if (typeof(body) === 'string') {
            // Remove null characters
            body = body.replace(/(?<!\\)\\u0000/g, '');
        }
        requestParams["body"] = body
    }

    const fullPath = apiConfig.baseUrl + apiConfig.version + url;

    // API Gateway has an integration timeout of 29 seconds.
    // This will abort the network request, preventing CORS errors.
    const abortTimeout = setTimeout(() => {
        NotificationAlert(
            "error", "", "Not able to complete request in time.",
            5, { key: "fetch-timeout"}
        );
        abortController.abort("Fetch Timeout");
        console.error("FetchTimeout:", method, fullPath);
    }, 28_500);

    fetch(fullPath, requestParams)
        .then((res) => {
            clearTimeout(abortTimeout);
            success(res);
        })
        .catch((err) => {
            clearTimeout(abortTimeout);
            error(err);
        });
}


export function get_team_promise(uuid) {

    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    headers["Authorization"] = getAuthToken();
    headers["Team"] = team_cookie;
    headers["Organization"] = organization_uuid;
    headers["KIOSK_MODE"] = kiosk_mode_header;
    headers["IS_APP"] = isMobileApp() ? "yes" : "no";

    headers["DEVICE_TYPE"] = getCookie("DEVICE_TYPE");
    headers["DEVICE_MODEL_NAME"] = getCookie("DEVICE_MODEL_NAME");
    headers["OS_NAME"] = getCookie("OS_NAME");
    headers["OS_VERSION"] = getCookie("OS_VERSION");
    headers["DEVICE_UUID"] = getCookie("DEVICE_UUID");
    headers["APP_VERSION"] = getCookie("APP_VERSION");

    var requestParams = {
        method: 'GET',
        headers: headers,
    };

    if (!isIE() && !isMobileApp() && (window.innerWidth > 1100)) {
        abortController = new AbortController();
        requestParams["signal"] = abortController.signal
    }

    return new Promise(function (resolve, reject) {
        fetch(APIConfiguration.BASE_API_URL + APIConfiguration.BASE_VERSION + APIRoutes.GET_TEAM(uuid), requestParams).then(function (success) {
            if (success.ok) {
                resolve(success)
            } else {
                reject(success)
            }
        }).catch((e) => {
            if (e.code === 20) {
                console.log("aborted")
            }
        });

    });

}

//
// COOKIES HELPER FUNCTIONS
//

export function deleteCookies() {
    return deleteAllCookies();
}

export function get_auth_token() {
    return getAuthToken();
}

export function update_auth_token(cookie) {
    return updateAuthToken(cookie)
}

export function update_team_cookie(cookie) {
    return updateTeamCookie(cookie);
}

export function update_organization_uuid(uuid) {
    return updateOrganizationUUID(uuid);
}

export function get_organization_uuid() {
    return getCookie("Organization")
}

export function update_session_uuid(uuid) {
    return updateSessionUUID(uuid)
}

export function get_mobile_enabled_cookie() {
    return getMobileEnabledCookie();
}

export function update_kiosk_mode_header(value) {
    return updateKioskModeHeader(value)
}

export function update_redirect_route(route) {
    return updateRedirectRoute(route)
}

export function get_redirect_route_cookie() {
    return getCookie("RedirectRoute")
}

export async function logoutAndCleanupState() {
    sessionStorage.clear();
    try {
        const res = await logout();
        const data = await res.json();
        deleteAllCookies();
        if (data.kiosk_mode_on) {
            update_auth_token(data.kiosk_mode_token);
        }

        return data;
    } catch (err) {
        update_auth_token(undefined);
        console.error("Could not logout:", err);
        return null;
    }
}

//
// AUTHENTICATION & USER
//
export function logout() {
    return POSTPromise(APIRoutes.LOGOUT);
}

export function login(body) {
    return POSTPromise(APIRoutes.LOGIN, body);
}

export function oauth_login(body) {
    return POSTPromise(APIRoutes.OAUTH_LOGIN, body);
}

export function register(body) {
    return POSTPromise(APIRoutes.REGISTER, body);
}

export function create_admin(body) {
    return POSTPromise(APIRoutes.CREATE_ADMIN, body);
}

export function create_user(body) {
    return POSTPromise(APIRoutes.CREATE_USER, body);
}

export function get_importing_users_async() {
    return GETPromise('/organizations/current/importing_users_async');
}

export function check_multiple_users(body) {
    return POSTPromise("/organizations/current/check_multiple_users", body)
}

export function create_multuple_users(body) {
    return POSTPromise("/organizations/current/create_multiple_users", body)
}

export function file_upload_users(file, content_type) {
    return POSTPromise("/organizations/current/upload_users", file, content_type);
}

export function request_password_restore(body) {
    return POSTPromise(APIRoutes.REQUEST_PW_RESTORE, body);
}

export function password_restore(body) {
    return POSTPromise(APIRoutes.PASSWORD_RESTORE, body);
}

export function update_user_password(uuid, body) {
    return POSTPromise(APIRoutes.UPDATE_USER_PASSWORD(uuid), body)
}

export function update_user_password_admin(uuid, body) {
    return POSTPromise(APIRoutes.UPDATE_USER_PASSWORD_ADMIN(uuid), body)
}

export function resend_invite(team_uuid, user_uuid) {
    return POSTPromise(APIRoutes.RESEND_INVITE(team_uuid, user_uuid))
}

export function deactivate_user(user_uuid) {
    return POSTPromise(APIRoutes.DEACTIVATE_USER(user_uuid));
}

export function activate_user(user_uuid) {
    return POSTPromise(APIRoutes.ACTIVATE_USER(user_uuid));
}

export function get_deactivated_users() {
    return GETPromise(APIRoutes.GET_DEACTIVATED_USERS());
}

export function delete_pending_user(uuid) {
    return POSTPromise(APIRoutes.DELETE_PENDING_USER(uuid));
}

export function update_user(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_USER(uuid), body)
}

export function update_user_force(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_USER_FORCE(uuid), body)
}

export function get_user(uuid, query = "") {
    return GETPromise(APIRoutes.GET_USER(uuid, query))
}

export function current_user() {
    return GETPromise(APIRoutes.CURRENT_USER())
}

export function get_list_users(query = "") {
    return GETPromise(APIRoutes.GET_LIST_USERS(query))
}

export function remove_role(uuid, body) {
    return POSTPromise(APIRoutes.REMOVE_ROLE(uuid), body);
}

export function add_role(uuid, body) {
    return POSTPromise(APIRoutes.ADD_ROLE(uuid), body);
}

export function set_role(uuid, body) {
    return POSTPromise(APIRoutes.SET_ROLE(uuid), body);
}

export function get_sessions() {
    return GETPromise(APIRoutes.SESSIONS());
}

export function end_session(uuid) {
    return DELETEPromise(APIRoutes.END_SESSION(uuid));
}

export function get_timezones() {
    return GETPromise(APIRoutes.GET_TIMEZONES());
}

export function get_initial_data() {
    return GETPromise(APIRoutes.GET_INITIAL_DATA());
}

export function update_post_embedded_form(form_uuid) {
    const body = {
        post_embedded_form_uuid: form_uuid
    }
    return POSTPromise("/organizations/current/update_post_embedded_form", JSON.stringify(body));
}

//
// LIBRARY
//
export function getSharedLibrary(query) {
    return GETPromise(APIRoutes.SHARED_LIBRARY(query));
}

export function getSharedArticle(uuid) {
    return GETPromise(APIRoutes.GET_SHARED_ARTICLE(uuid));
}

export function getLibrary(query) {
    return GETPromise(APIRoutes.LIBRARY + query)
}

export function getCategories() {
    return GETPromise(APIRoutes.CATEGORIES)
}

export function makeArticle() {
    return POSTPromise(APIRoutes.MAKE_ARTICLE)
}

export function getArticle(uuid) {
    return GETPromise(APIRoutes.GET_ARTICLE(uuid))
}

export function updateArticle(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_ARTICLE(uuid), body)
}

export function getArticleActivities(uuid) {
    return GETPromise(APIRoutes.ARTICLE_ACTIVITIES(uuid))
}

export function getArticleViewers(uuid) {
    return GETPromise(APIRoutes.GET_ARTICLE_VIEWS(uuid))
}

export function deleteArticle(uuid) {
    return DELETEPromise(APIRoutes.DELETE_ARTICLE(uuid))
}

export function rejectArticle(uuid, body) {
    return POSTPromise(APIRoutes.REJECT_ARTICLE(uuid), body)
}

export function certifyArticle(uuid) {
    return POSTPromise(APIRoutes.CERTIFY_ARTICLE(uuid))
}

export function getArticleVersion(articleUUID, versionUUID) {
    return GETPromise(APIRoutes.GET_ARTICLE_VERSION(articleUUID, versionUUID));
}

export function revertArticleVersion(articleUUID, versionUUID) {
    return POSTPromise(APIRoutes.REVERT_ARTICLE_VERSION(articleUUID, versionUUID));
}

export function libraryRenameFile(uuid, body) {
    return POSTPromise(APIRoutes.LIBRARY_RENAME_FILE(uuid), body);
}

//
// BULLETIN BOARD
//

export function get_location_options(query) {
    return GETPromise(APIRoutes.LOCATION_OPTIONS(query));
}

export function get_bulletin_tags(query) {
    return GETPromise(APIRoutes.BULLETIN_TAGS(query))
}

export function update_tags(body) {
    return PUTPromise(APIRoutes.UPDATE_TAGS, body);
}

export function createTag(body) {
    return POSTPromise(APIRoutes.CREATE_TAG, body)
}

export function get_bulletin_categories(query) {
    return GETPromise(APIRoutes.BULLETIN_CATEGORIES(query));
}

export function update_categories(body) {
    return PUTPromise(APIRoutes.UPDATE_CATEGORIES, body);
}

export function createCategory(body) {
    return POSTPromise("/bulletin_board/categories", body);
}

export function get_post_drafts(query = "") {
    return GETPromise(APIRoutes.GET_POST_DRAFTS(query));
}

export function make_bulletin(body) {
    return POSTPromise(APIRoutes.MAKE_BULLETIN, body)
}

export function update_bulletin(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_BULLETIN(uuid), body)
}

export function get_bulletins(query) {
    return GETPromise(APIRoutes.LIST_OF_BULLETINS(query))
}

export function get_filter_options() {
    return GETPromise(APIRoutes.FILTER_OPTIONS());
}

export function getBulletinPost(uuid) {
    return GETPromise(APIRoutes.GET_BULLETIN_POST(uuid))
}

export function getPostViewers(uuid) {
    return GETPromise(APIRoutes.POST_VIEWERS(uuid))
}

export function deleteBulletinPost(uuid) {
    return DELETEPromise(APIRoutes.DELETE_POST(uuid))
}

export function severity_levels(query) {
    return GETPromise(APIRoutes.SEVERITY(query));
}

export function update_severity_levels(body) {
    return PUTPromise(APIRoutes.UPDATE_SEVERITY(), body);
}

export function get_sub_status(query) {
    return GETPromise(APIRoutes.GET_SUB_STATUS(query));
}

export function update_sub_statuses(body) {
    return PUTPromise(APIRoutes.UPDATE_SUB_STATUS(), body);
}

export function createSubStatus(body) {
    return POSTPromise("/bulletin_board/sub_statuses", body)
}

export function reopen_post(uuid, body) {
    return POSTPromise(APIRoutes.REOPEN_POST(uuid), body);
}

export function close_post(uuid, body) {
    return POSTPromise(APIRoutes.CLOSE_POST(uuid), body);
}

export function share_post(uuid, body) {
    return POSTPromise(APIRoutes.SHARE_POST(uuid), body);
}

export function share_options(uuid) {
    return GETPromise(APIRoutes.SHARE_OPTIONS(uuid));
}

export function move_post(uuid, body) {
    return POSTPromise(APIRoutes.MOVE_POST(uuid), body);
}

export function copy_post(uuid) {
    return POSTPromise(APIRoutes.COPY_POST(uuid));
}


export function export_post(uuid, body) {
    return POSTPromise(APIRoutes.EXPORT_POST(uuid), body);
}

export function set_due_date(uuid, body) {
    return POSTPromise(APIRoutes.DUE_DATE(uuid), body);
}

export function add_post_view(uuid, body) {
    return POSTPromise(APIRoutes.ADD_VIEW(uuid), body);
}

export function get_comments(uuid) {
    return GETPromise(APIRoutes.GET_COMMENTS(uuid));
}

export function add_comment(uuid, body) {
    return POSTPromise(APIRoutes.ADD_COMMENT(uuid), body);
}

export function edit_comment(post_uuid, comment_uuid, body) {
    return PUTPromise(APIRoutes.EDIT_COMMENT(post_uuid, comment_uuid), body);
}

export function delete_comment(post_uuid, comment_uuid) {
    return DELETEPromise(APIRoutes.DELETE_COMMENT(post_uuid, comment_uuid));
}

export function add_reaction_comment(post_uuid, comment_uuid, emoji) {
    return POSTPromise(APIRoutes.ADD_REACTION_COMMENT(post_uuid, comment_uuid, emoji));
}

export function delete_reaction_comment(post_uuid, comment_uuid, emoji) {
    return DELETEPromise(APIRoutes.DELETE_REACTION_COMMENT(post_uuid, comment_uuid, emoji));
}

export function add_reaction(post_uuid, emoji) {
    return POSTPromise(APIRoutes.ADD_REACTION(post_uuid, emoji));
}

export function delete_reaction(post_uuid, emoji) {
    return DELETEPromise(APIRoutes.DELETE_REACTION(post_uuid, emoji));
}

export function get_my_assignments() {
    return GETPromise(APIRoutes.MY_ASSIGNMENTS());
}

export function assign_user(post_uuid, body) {
    return POSTPromise(APIRoutes.ASSIGN_USER(post_uuid), body);
}

export function unassign_user(post_uuid, body) {
    return POSTPromise(APIRoutes.UNASSIGN_USER(post_uuid), body);
}

export function get_assign_users(body) {
    return POSTPromise(APIRoutes.GET_ASSIGN_USERS(), body);
}

export function export_feed_analytics(body) {
    return POSTPromise(APIRoutes.FEED_ANALYTICS_EXPORT, body);
}

export function get_templates() {
    return GETPromise(APIRoutes.GET_TEMPLATES());
}

export function create_template(body) {
    return POSTPromise(APIRoutes.CREATE_TEMPLATE(), body);
}

export function update_template(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_TEMPLATE(uuid), body);
}

export function get_template(uuid) {
    return GETPromise(APIRoutes.GET_TEMPLATE(uuid));
}

export function delete_template(uuid) {
    return DELETEPromise(APIRoutes.DELETE_TEMPLATE(uuid));
}

export function get_subscriber_user_options(body) {
    return POSTPromise(APIRoutes.SUBSCRIBE_USER_OPTIONS(), body);
}

export function get_post_subscribers(post_uuid, options = null) {
    return GETPromise(APIRoutes.POST_SUBSCRIBERS(post_uuid), options);
}

export function subscribe_users(post_uuid, body) {
    return POSTPromise(APIRoutes.SUBSCRIBE_USERS(post_uuid), body);
}

export function unsubscribe_users(post_uuid, body) {
    return POSTPromise(APIRoutes.UNSUBSCRIBE_USERS(post_uuid), body);
}

export function getPostBulkOptions() {
    return GETPromise('/bulletin_board/offline_data');
}

export function addAttachmentToPost(postId, body) {
    return POSTPromise(`/bulletin_board/${postId}/attachments`, body);
}

export function getPostAttachments(postId) {
    return GETPromise(`/bulletin_board/${postId}/attachments`);
}

export function deletePostAttachments(postId, body) {
    return POSTPromise(`/bulletin_board/${postId}/delete_attachments`, body);
}

/**
 * @param {string} postId 
 * @param {string} subStatusId 
 * @param {number} group
 * @returns {Promise<Response>}
 */
export function updatePostStatus(postId, subStatusId, group) {
    const body = JSON.stringify({
        sub_status_uuid: subStatusId,
        group,
    });
    return POSTPromise(`/bulletin_board/${postId}/change_status`, body);
}

//
// DASHBOARD
//

export function panels_info() {
    return GETPromise(APIRoutes.PANELS_INFO)
}

export function dashboard_info() {
    return GETPromise(APIRoutes.DASHBOARD_INFO)
}

export function get_open_assignments(query) {
    return GETPromise(APIRoutes.GET_OPEN_ASSIGNMENTS(query));
}

export function get_closed_assignments(query) {
    return GETPromise(APIRoutes.GET_CLOSED_ASSIGNMENTS(query));
}

//
// TEAM MANAGEMENT 
//

export function create_team(body) {
    return POSTPromise(APIRoutes.CREATE_TEAM, body)
}

export function get_team(uuid) {
    return GETPromise(APIRoutes.GET_TEAM(uuid))
}

export function get_my_teams_data() {
    return GETPromise(APIRoutes.GET_MY_TEAMS_DATA);
}

export function update_team(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_TEAM(uuid), body)
}

export function delete_team(uuid) {
    return DELETEPromise(APIRoutes.DELETE_TEAM(uuid))
}

export function get_list_teams(query = "") {
    let url = "/teams";
    if (query.length > 0) {
        url += `?${query}`;
    }
    return GETPromise(url);
}

export async function getTeamsList(query = "") {
    const res = await get_list_teams(query);
    return joinTeamsAndMembers(await res.json());
}

export function get_users_in_team(uuid, query = "") {
    return GETPromise(APIRoutes.USERS_IN_TEAM(uuid, query))
}

export function invite_users(uuid, body) {
    return POSTPromise(APIRoutes.INVITE_USERS(uuid), body)
}

export function mass_invite_warnings(body) {
    return POSTPromise(APIRoutes.MASS_INVITE_WARNINGS(), body);
}

export function mass_invite(body) {
    return POSTPromise(APIRoutes.MASS_INVITE(), body);
}

export function add_users_to_team(uuid, body) {
    return POSTPromise(APIRoutes.ADD_USERS(uuid), body)
}

export function remove_user_from_team(uuid, body) {
    return POSTPromise(APIRoutes.REMOVE_USER(uuid), body)
}

export function register_user(body) {
    return POSTPromise(APIRoutes.REGISTER_USER_LINK, body)
}

export function change_user_teams(body) {
    return POSTPromise(APIRoutes.CHANGE_USER_TEAMS, body);
}

export function getTeamMemberships() {
    return GETPromise('/teams/memberships');
}

//
// ORGANIZATION
//
export function get_organization() {
    return GETPromise(APIRoutes.GET_ORGANIZATION);
}

export function update_organization(body) {
    return PUTPromise(APIRoutes.UPDATE_ORGANIZATION, body);
}

export function create_organization(body) {
    return POSTPromise(APIRoutes.CREATE_ORGANIZATION, body);
}

export function toggle_incidents_enabled() {
    return POSTPromise(APIRoutes.TOGGLE_INCIDENTS_ENABLED);
}

export function toggle_risklevel_enabled() {
    return POSTPromise(APIRoutes.TOGGLE_RISKLEVEL_ENABLED);
}

export function toggle_post_due_date_enabled() {
    return POSTPromise(APIRoutes.TOGGLE_POST_DUE_DATE_ENABLED);
}

export function toggle_location_enabled() {
    return POSTPromise(APIRoutes.TOGGLE_LOCATION_ENABLED);
}

export function toggle_feature_flag(name, body) {
    return PUTPromise(APIRoutes.TOGGLE_FEATURE_FLAG(name), body);
}

export function toggle_zero_library() {
    return POSTPromise('/organizations/current/enable_zero_library_toggle');
}

export function update_custom_post_field(body) {
    return POSTPromise(APIRoutes.UPDATE_CUSTOM_POST_FIELD, body)
}

export function update_post_field_names(body) {
    return PUTPromise(APIRoutes.UPDATE_POST_FIELD_NAMES, body)
}

//
// ANALYTICS
//

export function get_categories_analytics_chart(query) {
    return GETPromise(APIRoutes.ANALYTICS_CATEGORIES_CHART(query));
}

export function get_popular_key_words_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_POPULAR_KEYWORDS(query));
}

export function get_time_to_close_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_TIME_TO_CLOSE(query));
}

export function get_severity_level_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_SEVERITY_LEVEL(query));
}

export function get_bulletin_tags_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_BULLETIN_TAGS(query))
}

export function get_bulletin_categories_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_BULLETIN_CATEGORIES(query));
}

export function get_substatus_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_SUB_STATUS(query));
}

export function get_leading_indicators(query) {
    return GETPromise(APIRoutes.ANALYTICS_LEADING_INDICATORS(query));
}

export function get_feed_activity(query) {
    return GETPromise(APIRoutes.ANALYTICS_FEED_ACTIVITY(query));
}

export function get_users_analytics_chart(query) {
    return GETPromise(APIRoutes.ANALYTICS_USERS_CHART(query));
}

export function get_users_analytics_panel(query) {
    return GETPromise(APIRoutes.ANALYTICS_USERS_PANEL(query));
}

export function get_top_posters(query) {
    return GETPromise(APIRoutes.ANALYTICS_TOP_POSTERS(query));
}

export function get_members_analytics(query) {
    return GETPromise(APIRoutes.ANALYTICS_MEMBERS(query));
}

export function get_user_analytics(uuid, query = "") {
    return GETPromise(APIRoutes.ANALYTICS_USER(uuid, query));
}

//
// INCIDENTS
//

export function create_location(body) {
    return POSTPromise(APIRoutes.CREATE_LOCATION(), body);
}

export function get_location(uuid) {
    return GETPromise(APIRoutes.GET_LOCATION(uuid));
}

export function get_locations(query = '') {
    return GETPromise(APIRoutes.GET_LOCATIONS(query));
}

export function update_location(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_LOCATION(uuid), body);
}

export function delete_location(uuid) {
    return DELETEPromise(APIRoutes.DELETE_LOCATION(uuid));
}

export function create_incident(body) {
    return POSTPromise(APIRoutes.CREATE_INCIDENT(), body);
}

export function get_incident(uuid) {
    return GETPromise(APIRoutes.GET_INCIDENT(uuid));
}

export function get_incidents(query) {
    return GETPromise(APIRoutes.GET_INCIDENTS(query));
}

export function get_incident_drafts() {
    return GETPromise(APIRoutes.GET_INCIDENT_DRAFTS());
}

export function update_incident(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_INCIDENT(uuid), body);
}

export function delete_incident(uuid) {
    return DELETEPromise(APIRoutes.DELETE_INCIDENT(uuid));
}

export function get_incident_options(query = '') {
    return GETPromise(APIRoutes.GET_INCIDENT_OPTIONS(query))
}

export function make_related_post(uuid, body) {
    return POSTPromise(APIRoutes.MAKE_RELATED_POST(uuid), body);
}

export function move_incident(uuid, body) {
    return POSTPromise(APIRoutes.MOVE_INCIDENT(uuid), body);
}

export function create_corrective_action(uuid, body) {
    return POSTPromise(APIRoutes.CREATE_CORRECTIVE_ACTION(uuid), body);
}

export function delete_corrective_action(incident_uuid, action_uuid) {
    return DELETEPromise(APIRoutes.DELETE_CORRECTIVE_ACTION(incident_uuid, action_uuid));
}

export function update_corrective_action(incident_uuid, action_uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_CORRECTIVE_ACTION(incident_uuid, action_uuid), body);
}

export function get_corrective_actions(uuid) {
    return GETPromise(APIRoutes.GET_CORRECTIVE_ACTIONS(uuid));
}

export function get_event_types(query) {
    return GETPromise(APIRoutes.GET_EVENT_TYPES(query));
}

export function update_event_types(body) {
    return PUTPromise(APIRoutes.GET_EVENT_TYPES(""), body);
}

export function get_incident_causes(query) {
    return GETPromise(APIRoutes.GET_INCIDENT_CAUSES(query));
}

export function update_incident_causes(body) {
    return PUTPromise(APIRoutes.GET_INCIDENT_CAUSES(""), body)
}

export function get_incident_injuries(query) {
    return GETPromise(APIRoutes.INCIDENT_INJURIES(query));
}

export function update_incident_injuries(body) {
    return PUTPromise(APIRoutes.INCIDENT_INJURIES(""), body);
}

export function submit_incident_form(uuid, body) {
    return POSTPromise(APIRoutes.INCIDENT_FORM_SUBMISSION(uuid), body);
}

export function incidentRenameFile(uuid, body) {
    return POSTPromise(APIRoutes.INCIDENT_RENAME_FILE(uuid), body);
}

export function get_incident_managers(incidentId) {
    return GETPromise(`/incident_reporting/incidents/${incidentId}/managers`);
}

export function get_incident_comments(incidentId) {
    return GETPromise(`/incident_reporting/incidents/${incidentId}/comments`);
}

export function update_incident_status(incidentId, body) {
    return POSTPromise(`/incident_reporting/incidents/${incidentId}/set_status`, body);
}

export function createIncidentUserComment(incidentId, body) {
    return POSTPromise(`/incident_reporting/incidents/${incidentId}/user_comments`, body);
}

//
// INCIDENTS ANALYTICS
//

export function get_illness_info_analytics(query) {
    return GETPromise(APIRoutes.ILLNESS_INFO_ANALYTICS(query));
}

export function get_injury_type_analytics(query) {
    return GETPromise(APIRoutes.INJURY_TYPE_ANALYTICS(query));
}

export function get_body_front_parts_analytics(query) {
    return GETPromise(APIRoutes.BODY_FRONT_PARTS_ANALYTICS(query));
}

export function get_body_back_parts_analytics(query) {
    return GETPromise(APIRoutes.BODY_BACK_PARTS_ANALYTICS(query));
}

export function get_body_parts_analytics(query) {
    return GETPromise(APIRoutes.BODY_PARTS_ANALYTICS(query));
}

export function get_incident_dates_chart(query) {
    return GETPromise(APIRoutes.INCIDENT_DATE_CHART(query));
}

export function get_incident_hourly_chart(query) {
    return GETPromise(`/incident_reporting/analytics/incident_hourly_chart${query}`);
}

export function get_incident_totals_analytics(query) {
    return GETPromise(APIRoutes.INCIDENT_TOTALS_ANALYTICS(query));
}

export function get_incident_causes_analytics(query) {
    return GETPromise(APIRoutes.INCIDENT_CAUSES_ANALYTICS(query));
}

export function get_event_type_analytics(query) {
    return GETPromise(APIRoutes.EVENT_TYPE_ANALYTICS(query));
}

export function export_incident_analytics(body) {
    return POSTPromise(APIRoutes.INCIDENT_ANALYTICS_EXPORT, body);
}

export function export_incident_body_chart(body) {
    return POSTPromise(APIRoutes.INCIDENT_BODY_CHART_EXPORT, body);
}

//
// FORMS
//

export function get_forms_filters(query) {
    return GETPromise(APIRoutes.GET_FORMS_FILTERS(query));
}

export function get_form_categories() {
    return GETPromise(APIRoutes.GET_FORM_CATEGORIES());
}

export function update_form_categories(body) {
    return PUTPromise(APIRoutes.GET_FORM_CATEGORIES(), body);
}

export function get_forms(query) {
    return GETPromise(APIRoutes.GET_FORMS(query));
}

export function create_form(body) {
    return POSTPromise(APIRoutes.CREATE_FORM(), body);
}

export function get_form(uuid) {
    return GETPromise(APIRoutes.GET_FORM(uuid));
}

export function update_form(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_FORM(uuid), body);
}

export function delete_form(uuid) {
    return DELETEPromise(APIRoutes.DELETE_FORM(uuid));
}

export function make_form_copy(uuid, body) {
    return POSTPromise(APIRoutes.MAKE_FORM_COPY(uuid), body);
}

export function get_team_submissions(query) {
    return GETPromise(APIRoutes.GET_TEAM_SUBMISSIONS(query));
}

export function get_form_submissions(query) {
    return GETPromise(APIRoutes.GET_FORM_SUBMISSIONS(query));
}

export function get_simple_form_submissions(query) {
    return GETPromise(APIRoutes.GET_FORM_SUBMISSIONS2(query));
}

export function create_submission(body) {
    return POSTPromise(APIRoutes.CREATE_SUBMISSION(), body);
}

export function get_submission(sub_uuid) {
    return GETPromise(APIRoutes.GET_SUBMISSION(sub_uuid));
}

export function getBulkSubmissions(submissionIds) {
    const body = JSON.stringify(submissionIds);
    return POSTPromise('/forms/submissions/get_bulk', body);
}

export function getSubmissionComments(submissionId) {
    return GETPromise(`/forms/submissions/${submissionId}/comments`);
}

export function createSubmissionComment(submissionId, body) {
    if (typeof(body) !== 'string') {
        body = JSON.stringify(body);
    }
    return POSTPromise(`/forms/submissions/${submissionId}/comments`, body);
}

export function getSubmissionEditors(submissionId) {
    return GETPromise(`/forms/submissions/${submissionId}/editors`);
}

export function updateSubmissionStatus(submissionId, body) {
    return POSTPromise(`/forms/submissions/${submissionId}/update_status`, body);
}

export function update_submission(sub_uuid, body) {
    if (sub_uuid?.startsWith("offline")) {
        return;
    }
    return PUTPromise(APIRoutes.UPDATE_SUBMISSION(sub_uuid), body);
}

export function export_submission(sub_uuid) {
    return POSTPromise(APIRoutes.EXPORT_SUBMISSION(sub_uuid, ''));
}

export function link_post_to_field(sub_uuid, body) {
    return POSTPromise(APIRoutes.LINK_POST_TO_FIELD(sub_uuid), body);
}

export function get_form_drafts(query) {
    return GETPromise(APIRoutes.GET_FORM_DRAFTS(query));
}

export function delete_submission(sub_uuid) {
    // TODO: Find out where offline drafts are using delete API
    if (sub_uuid?.startsWith("offline")) {
        return;
    }
    return DELETEPromise(APIRoutes.DELETE_SUBMISSION(sub_uuid));
}

export function get_form_analytics(query) {
    return GETPromise(APIRoutes.FORM_ANALYTICS(query));
}

export function get_form_analytics_fields(query) {
    return GETPromise(APIRoutes.FORM_ANALYTICS_FIELDS(query));
}

export function get_form_charts(query) {
    return GETPromise(APIRoutes.FORMS_CHART(query))
}

export function get_share_form_options(uuid) {
    return GETPromise(APIRoutes.SHARE_FORM_OPTIONS(uuid));
}

export function update_form_public_settings(formId, body) {
    return PUTPromise(`/forms/${formId}/public_settings`, stringify(body));
}

export function share_form(uuid, body) {
    return POSTPromise(APIRoutes.SHARE_FORM(uuid), body);
}

export function copy_to_post(body) {
    return POSTPromise(APIRoutes.COPY_TO_POST(), body)
}

export function my_form_assignments(query = "") {
    return GETPromise(APIRoutes.MY_FORM_ASSIGNMENTS(query));
}

export function get_all_form_assignments(query = "") {
    return GETPromise(APIRoutes.ALL_FORM_ASSIGNMENTS(query));
}

export function get_assignment_analytics(query) {
    return GETPromise(APIRoutes.ASSIGNMENTS_ANALYTICS(query));
}

export function get_form_templates(query = "") {
    return GETPromise(APIRoutes.GET_FORM_TEMPLATES(query));
}

export function create_pre_made_form(uuid, body) {
    return POSTPromise(APIRoutes.CREATE_PREMADE_FORM(uuid), body);
}

export function export_form_analytics(body) {
    return POSTPromise(APIRoutes.FORM_ANALYTICS_EXPORT, body);
}

export function export_courses_analytics(body) {
    return POSTPromise(APIRoutes.COURSES_ANALYTICS_EXPORT, body);
}

//
// FORM DYNAMIC LISTS
//

export function get_dynamic_lists(query = '') {
    return GETPromise(`/forms/lists${query}`);
}

export function get_dynamic_list(id) {
    return GETPromise(`/forms/lists/${id}`);
}

//
// FORM SCHEDULES
//

export function get_schedules(query = "") {
    return GETPromise(APIRoutes.SCHEDULES(query));
}

export function get_schedule(uuid) {
    return GETPromise(APIRoutes.GET_SCHEDULE(uuid));
}

export function get_schedule_subscribers(uuid) {
    return GETPromise(`/forms/schedulers/${uuid}/subscribers`);
}

export function get_schedule_is_locked(uuid) {
    return GETPromise(APIRoutes.GET_SCHEDULE_IS_LOCKED(uuid));
}

export function create_schedule(body) {
    return POSTPromise(APIRoutes.CREATE_SCHEDULE(), body);
}

export function update_schedule(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_SCHEDULE(uuid), body);
}

export function archive_schedule(uuid) {
    return DELETEPromise(APIRoutes.ARCHIVE_SCHEDULE(uuid));
}

export function schedule_analytics(uuid, query) {
    return GETPromise(APIRoutes.SCHEDULE_ANALYTICS(uuid, query));
}

export function update_schedule_subs(uuid, body) {
    return POSTPromise(APIRoutes.UPDATE_SCHEDULE_SUBS(uuid), body);
}

//
// FORM ASSIGNMENTS
//

export function get_assignment(uuid) {
    return GETPromise(APIRoutes.GET_ASSIGNMENT(uuid));
}

export function patchAssignment(uuid, body) {
    return PATCHPromise(APIRoutes.PATCH_ASSIGNMENT(uuid), body);
}

export function get_assignment_filters(query) {
    return GETPromise(APIRoutes.ASSIGNMENT_FILTERS(query));
}

export function delete_assignment(uuid) {
    return DELETEPromise(APIRoutes.DELETE_ASSIGNMENT(uuid))
}

// 
// ALERTS
// 

export function getAllAlerts() {
    return GETPromise(APIRoutes.GET_ALL_ALERTS());
}

export function getAlert(uuid) {
    return GETPromise(APIRoutes.GET_ALERT(uuid));
}

export function getFormSubmissionAlertOptions() {
    return GETPromise(APIRoutes.GET_FORM_SUB_ALERT_OPTIONS());
}

export function getAllFormSubmissionAlerts() {
    return GETPromise(APIRoutes.GET_FORM_SUB_ALERTS());
}

export function getFormSubmissionAlert(uuid) {
    return GETPromise(APIRoutes.GET_FORM_SUB_ALERT(uuid));
}

export function createFormSubmissionAlert(body) {
    return POSTPromise(APIRoutes.CREATE_FORM_SUB_ALERT(), body);
}

export function patchFormSubmissionAlert(uuid, body) {
    return PATCHPromise(APIRoutes.PATCH_FORM_SUB_ALERT(uuid), body);
}

export function deleteFormSubmissionAlert(uuid) {
    return DELETEPromise(APIRoutes.DELETE_FORM_SUB_ALERT(uuid));
}

export function getIncidentAlertOptions() {
    return GETPromise(APIRoutes.GET_INCIDENT_ALERT_OPTIONS());
}

export function getAllIncidentAlerts() {
    return GETPromise(APIRoutes.GET_INCIDENT_ALERTS());
}

export function getIncidentAlert(uuid) {
    return GETPromise(APIRoutes.GET_INCIDENT_ALERT(uuid));
}

export function createIncidentAlert(body) {
    return POSTPromise(APIRoutes.CREATE_INCIDENT_ALERT(), body);
}

export function patchIncidentAlert(uuid, body) {
    return PATCHPromise(APIRoutes.PATCH_INCIDENT_ALERT(uuid), body);
}

export function deleteIncidentAlert(uuid) {
    return DELETEPromise(APIRoutes.DELETE_INCIDENT_ALERT(uuid));
}

export const newPostAlertsApi = createCrudApi('/alerts/new_post', {
    options() {
        return GETPromise(`${this.baseUrl}/options`);
    },
});

//
// NOTIFICATIONS
//

export function get_notifications(query = "") {
    return GETPromise(APIRoutes.GET_NOTIFICATIONS(query));
}

export function notifications_mark_as_read() {
    return POSTPromise(APIRoutes.NOFITICATIONS_MARK_ALL_AS_READ());
}

export function mark_notification_as_read(uuid) {
    return POSTPromise(APIRoutes.NOFITICATION_MARK_READ(uuid))
}

export function subscribe_notifications(body) {
    return POSTPromise(APIRoutes.SUBSCRIBE_PUSH_NOTIFICATIONS(), body)
}

export function unsubscribe_notifications(body) {
    return POSTPromise(APIRoutes.UNSUBSCRIBE_PUSH_NOTIFICATIONS(), body)

}

export function test_push_notifications(body) {
    return POSTPromise(APIRoutes.TEST_PUSH_NOTIFICATIONS(), body);
}

export function test_push_notifications_dev(body) {
    return POSTPromise(APIRoutes.TEST_PUSH_NOTIFICATIONS_DEV(), body);
}

export function test_push_notifications_js_dev(body) {
    return POSTPromise(APIRoutes.TEST_PUSH_NOTIFICATION_JS_DEV(), body);
}

export function test_sms_notification() {
    return POSTPromise(APIRoutes.TEST_SMS_NOTIFICATIONS());
}

export function get_notification_settings() {
    return GETPromise(APIRoutes.GET_NOTIFICATION_SETTINGS());
}

export function get_all_browser_notification_settings() {
    return GETPromise(APIRoutes.GET_ALL_BROWSER_NOTIFICATION_SETTINGS());
}

export function update_notification_settings(body) {
    return PUTPromise(APIRoutes.UPDATE_NOTIFICATION_SETTINGS(), body);
}

// 
// REPORTS 
//

export function post_account_report(body) {
    return POSTPromise(APIRoutes.ACCOUNT_REPORT(), body);
}

export function post_directory_report(body) {
    return POSTPromise(APIRoutes.DIRECTORY_REPORT(), body);
}

export function post_team_report(body) {
    return POSTPromise(APIRoutes.TEAM_REPORT(), body);
}

export function post_open_posts_report(body) {
    return POSTPromise(APIRoutes.OPEN_POSTS_REPORT(), body);
}

export function feed_posts_report(body) {
    return POSTPromise(APIRoutes.FEED_POSTS_REPORT(), body);
}

export function incident_export(uuid) {
    return POSTPromise(APIRoutes.INCIDENT_EXPORT(uuid));
}

export function incident_export_xls(uuid) {
    return POSTPromise(APIRoutes.INCIDENT_EXPORT_XLS(uuid));
}

export function incidents_report(body) {
    return POSTPromise(APIRoutes.INCIDENTS_REPORT(), body);
}

export function incidents_report_history() {
    return GETPromise(APIRoutes.INCIDENTS_REPORT_HISTORY());
}

export function masterIncidentReport(body) {
    return POSTPromise('/incident_reporting/exports/master_report', body);
}

export function get_reports_history() {
    return GETPromise(APIRoutes.GET_REPORTS_HISTORY());
}

export function get_form_report_data(query = "") {
    return GETPromise(APIRoutes.FORM_REPORT_DATA(query));
}

export function get_feed_report_data(query = "") {
    return GETPromise(APIRoutes.FEED_REPORT_DATA(query));
}

export function form_submission_report(body) {
    return POSTPromise(APIRoutes.FORM_REPORTS(), body)
}

export function get_form_reports_history(body) {
    return GETPromise(APIRoutes.FORM_REPORTS_HISTORY(), body)
}

export function get_feed_reports_history(body) {
    return GETPromise(APIRoutes.FEED_REPORTS_HISTORY(), body)
}

export function get_schedule_report_data(query = "") {
    return GETPromise(APIRoutes.SCHEDULE_REPORT_DATA(query));
}

export function generate_schedule_report(body) {
    return POSTPromise(APIRoutes.GENERATE_SCHEDULE_REPORT(), body)
}

export function generate_assignment_report(body) {
    return POSTPromise(APIRoutes.GENERATE_ASSIGNMENT_REPORT(), body)
}

export function create_report_schedule(body) {
    return POSTPromise(APIRoutes.CREATE_REPORT_SCHEDULE(), body);
}

export function update_report_schedule(uuid, body) {
    return PUTPromise(APIRoutes.UPDATE_REPORT_SCHEDULE(uuid), body);
}

export function patch_report_schedule(uuid, body) {
    return PATCHPromise(APIRoutes.UPDATE_REPORT_SCHEDULE(uuid), body);
}

export function get_report_schedules() {
    return GETPromise(APIRoutes.GET_REPORT_SCHEDULE_LIST());
}

export function course_report(body) {
    return POSTPromise(APIRoutes.COURSE_REPORT(), body)
}

export function course_schedule_report(body) {
    return POSTPromise(APIRoutes.LMS_SCHEDULE_REPORT(), body)
}

export function course_assignment_report(body) {
    return POSTPromise(APIRoutes.LMS_ASSIGNMENT_REPORT(), body)
}

export function get_lms_report_history() {
    return GETPromise(APIRoutes.LMS_REPORT_HISTORY())
}

export function post_notification_report(body) {
    return POSTPromise("/reports/post_notification_report", body);
}

//
// MANAGE API KEYS
//

export function get_api_keys() {
    return GETPromise(APIRoutes.GET_API_KEYS());
}

export function create_api_key(body) {
    return POSTPromise(APIRoutes.CREATE_API_KEY(), body);
}

export function delete_api_key(uuid) {
    return DELETEPromise(APIRoutes.DELETE_API_KEY(uuid));
}

export function get_api_key(uuid) {
    return GETPromise(APIRoutes.GET_API_KEY(uuid))
}

export function update_api_key(uuid, body) {
    return PATCHPromise(APIRoutes.PATCH_API_KEY(uuid), body)
}

//
// SPACES API
//

export function get_desk_pool(user_uuid) {
    let url = '/spaces/desks/pool';
    if (user_uuid) {
        url += `?user_uuid=${user_uuid}`;
    }
    return GETPromise(url);
}

export function get_desk_pool_multiple_dates(body) {
    return POSTPromise('/spaces/desks/pool/multiple_dates', body);
}

export function create_location_survey(location_uuid, body) {
    return POSTPromise(`/incident_reporting/locations/${location_uuid}/survey`, body);
}

export function get_location_survey(location_uuid) {
    return GETPromise(`/incident_reporting/locations/${location_uuid}/survey`);
}

export function get_user_directory(query = '') {
    return GETPromise(`/spaces/directory${query}`);
}

export function create_check_in(body) {
    return POSTPromise('/spaces/check_ins', body);
}

export function create_future_check_ins(body) {
    return POSTPromise('/spaces/check_ins/create_future_check_ins', body);
}

export function get_check_in_dates({query = ''}) {
    return GETPromise(`/spaces/check_ins/current_and_future_dates${query}`);
}

export function update_check_in(checkInId, body) {
    return PATCHPromise(`/spaces/check_ins/${checkInId}`, body);
}

export function get_check_in_stats() {
    return GETPromise('/spaces/check_ins/stats');
}

export function get_check_in(checkInId) {
    return GETPromise(`/spaces/check_ins/${checkInId}`);
}

export function get_all_check_ins({query}) {
    return GETPromise(`/spaces/check_ins${query}`);
}

export function delete_check_in(checkInId) {
    return DELETEPromise(`/spaces/check_ins/${checkInId}`);
}

export function get_desks({query}) {
    return GETPromise(`/spaces/desks${query}`);
}

export function get_desks_filter_options() {
    return GETPromise(`/spaces/desks/filter_options`);
}

export function get_desk(deskId) {
    return GETPromise(`/spaces/desks/${deskId}`);
}

export function update_desk(deskId, body) {
    return PATCHPromise(`/spaces/desks/${deskId}`, body);
}

export function create_desk(body) {
    return POSTPromise('/spaces/desks', body);
}

export function delete_desk(deskId) {
    return DELETEPromise(`/spaces/desks/${deskId}`);
}

export function get_check_in_alerts() {
    return GETPromise(`/spaces/reminder_alerts`);
}

export function update_check_in_alert(alertId, body) {
    return PATCHPromise(`/spaces/reminder_alerts/${alertId}`, body);
}

export function create_check_in_alert(body) {
    return POSTPromise(`/spaces/reminder_alerts`, body);
}

export function delete_check_in_alert(alertId) {
    return DELETEPromise(`/spaces/reminder_alerts/${alertId}`);
}

export function get_spaces() {
    return GETPromise('/spaces/spaces');
}

export function update_space(space_uuid, body) {
    return PATCHPromise(`/spaces/spaces/${space_uuid}`, body);
}

export function create_space(body) {
    return POSTPromise('/spaces/spaces', body);
}

export function get_spaces_locations() {
    return GETPromise('/spaces/locations');
}

export function get_spaces_managers() {
    return GETPromise('/spaces/managers');
}

export function set_spaces_managers(body) {
    return POSTPromise('/spaces/managers', body);
}

//
// Custom Fields
//

export function get_custom_fields() {
    return GETPromise('/custom_fields/fields');
}

export function get_custom_field(type) {
    return GETPromise(`/custom_fields/fields/${type}`);
}

export function patch_custom_field(type, body) {
    return PATCHPromise(`/custom_fields/fields/${type}`, stringify(body));
}

export function patch_custom_field_option(uuid, body) {
    return PATCHPromise(`/custom_fields/options/${uuid}`, stringify(body));
}

//
// KIOSK MODE
//

export function kiosk_login(body) {
    return POSTPromise(APIRoutes.KIOSK_LOGIN, body);
}

export function kiosk_turn_on(body) {
    return POSTPromise(APIRoutes.KIOSK_TURN_ON, body);
}

export function kiosk_turn_off(uuid) {
    return DELETEPromise(APIRoutes.KIOSK_TURN_OFF(uuid));
}

export function kiosk_rename_device(uuid, body) {
    return PUTPromise(APIRoutes.KIOSK_RENAME_DEVICE(uuid), body);
}

export function kiosk_get_sessions() {
    return GETPromise(APIRoutes.KIOSK_GET_SESSIONS);
}

export function kiosk_get_users() {
    return GETPromise(APIRoutes.GET_KIOSK_USERS);
}

export function kiosk_create_user(body) {
    return POSTPromise(APIRoutes.CREATE_KIOSK_USER, body);
}


export function get_presigned_url(body) {
    return POSTPromise(APIRoutes.GET_PRESIGNED_URL, body);
}

export function get_short_link(linkId) {
    return GETPromise(APIRoutes.GET_SHORT_LINK(linkId));
}

export function disable_short_link(linkId) {
    return DELETEPromise(`/links/${linkId}`);
}

export function get_public_links_for_form(formId) {
    return GETPromise(`/links/public_form/${formId}`);
}

export function create_public_link_for_form(formId, teamId) {
    return POSTPromise(`/links/public_form/${formId}/${teamId}`);
}

export function login_as_user(body) {
    return POSTPromise('/login_as_user', body);
}

export function notifyError(error) {
    if (error.status === 422) {
        error.json().then(error => {
            let error_messages = safe_get(error, "error_messages", []);

            if (error_messages.length !== 0) {
                // let errors = safe_get.default(error_messages[0], "errors", [])
                // NotificationAlert.default('error', 'Oops!', errors[0])

                var errorMsg = safe_get(error_messages[0], "message", "");
                if (errorMsg === "") {
                    errorMsg = error_messages[0]
                }
                NotificationAlert('error', 'Oops!', errorMsg)

            }
        });
    }
}

export function handleError(arr) {
    var alerts = []
    for (var i in arr) {
        var obj = arr[i];
        if (typeof obj === 'string') {
            alert(obj);
        } else {
            for (var key in obj) {
                var value = obj[key];
                alerts.push(" " + value)
            }
        }
    }
    alert("Error: " + alerts)
}

export async function checkApiStatus() {
    try {
        if (window.zeroDebugForceOfflineMode) {
            return false;
        }

        const controller = new AbortController();
        const {signal} = controller;
        const url = `${CONFIG.BASE_API_URL()}/status`;

        const timeout = setTimeout(() => {
            controller.abort();
        }, 5_000);

        const response = await fetch(url, {signal});
        clearTimeout(timeout);
        return response.ok;
    } catch (err) {
        return false;
    }
}

// TASKS

export const tasksApi = createCrudApi('/tasks');


// PUBLIC API

export function publicGetForm(formId) {
    return PublicGetRequest(`/forms/${formId}`);
}

export function publicSubmitForm(formId, teamId, body) {
    return PublicPostRequest(`/forms/${formId}/${teamId}`, body);
}

export function publicGetSubmission(submissionId, token) {
    return PublicGetRequest(`/forms/submissions/${submissionId}?token=${token}`);
}

export function publicUpdateSubmission(submissionId, token, body) {
    return PublicPutRequest(`/forms/submissions/${submissionId}?token=${token}`, body);
}

export function publicOrgFromTeamId(teamId) {
    return PublicGetRequest(`/organization_from_team_id/${teamId}`);
}

export function publicPresignFileUpload(body) {
    return PublicPostRequest('/presign_file_upload', body);
}
