// @ts-check

import { UserAddOutlined } from '@ant-design/icons';
import useOrganizationId from "hooks/useOrganizationId";
import { useMemo } from "react";
import RespondersPopover from "./RespondersPopover";
import UserAvatar from "./UserAvatar";
import UserPopoverCard from "./UserPopoverCard";
import { useCachedDirectory } from 'other/directoryUtils';

// Shows a list of avatars with a button to change the assignments

export default function AvatarAssignmentList({
    assignedUsers,
    userFilter = undefined,
    onChange = (userIds) => {},
    maxAvatarsToShow = 3,
    showMassSelectOptions = true,
    showRemoveAction = false,
    removeActionText = undefined,
    onRemoveUser = () => {},
}) {
    const orgId = useOrganizationId();
    const availableUsers = useCachedDirectory(userFilter);

    const renderedAvatars = useMemo(() => {
        if (assignedUsers.length === 0) {
            return null;
        }

        return [...assignedUsers].slice(0, maxAvatarsToShow).map((user, index) => (
            <UserPopoverCard
                key={user.uuid ?? user.user_uuid ?? index}
                user={user}
                showRemoveAction={showRemoveAction}
                removeActionText={removeActionText}
                onRemoveUser={onRemoveUser}
            >
                <button className="ButtonLink">
                    <UserAvatar user={user} imgClass="img-sm img-circle bulletin" initialsClass="bulletin extra-top-margin" />
                </button>
            </UserPopoverCard>
        ))
    }, [assignedUsers, maxAvatarsToShow, orgId, showRemoveAction, removeActionText, onRemoveUser]);

    return (
        <div className="flex gap-sm" style={{alignItems: 'center', height: '100%'}}>
            { renderedAvatars }
            <RespondersPopover
                available_responders={availableUsers}
                selected_responders={assignedUsers}
                updateResponders={onChange}
                showSelectNoneOption={showMassSelectOptions}
                title={'Assignees'}
            >
                { assignedUsers.length > maxAvatarsToShow ?
                    (
                        <div className={"default-avatar-circle bulletin"}
                                style={{display: "inline-block", border: "1px solid #505050"}}>
                            <p className="initials"
                                style={{color: "#505050"}}>+{assignedUsers.length - maxAvatarsToShow}</p>
                        </div> 
                    ) : (
                        <UserAddOutlined style={{fontSize: "19px", verticalAlign: 'middle'}}/>
                    )
                }
            </RespondersPopover>
        </div>
    );
}