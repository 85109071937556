// @ts-check

import { Input, Select, Tabs } from "antd";
import Button from "components/Shared/Button";
import { BorderedPanel, MainContent, PageContent, PageHead, PageHeader } from "components/Shared/PageParts";
import TimePeriodFilter from "components/TimePeriodFilter";
import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import TaskDataGrid from "./TaskDataGrid/TaskDataGrid";
import { TaskDataGridContextProvider, useTaskDataGridContext } from "./TaskDataGrid/TaskDataGrid.context";
import { TASK_STATUS_FILTER_OPTIONS } from "./tasks";
import { TaskDataGridModals } from "./TaskDataGrid/TaskDataGrid.modals";
import DelayedTextInput from "components/Shared/DelayedTextInput";
import { useCurrentUser } from "hooks/reduxHooks";
import { isAdmin } from "other/Helper";
import ResetTaskGridLink from "./TaskDataGrid/ResetTaskGridLink";
import TaskDataGridExportButton from "./TaskDataGrid/TaskDataGridExportButton";
import TaskDataGridReloadButton from "./TaskDataGrid/TaskDataGridReloadButton";

function useTabRouteSync(tabPathName = 'tab') {
    const params = useParams();
    const history = useHistory();

    const currentTabParam = params[tabPathName];

    const onTabChange = useCallback((newTab) => {
        if (newTab === currentTabParam) return;

        const tabInPath = !!currentTabParam;
        let newPath = '';

        if (!tabInPath) {
            newPath = `${window.location.pathname}/${newTab}`;
        } else {
            const pathSegments = window.location.pathname.split('/').slice(0, -1);
            pathSegments.push(newTab);
            newPath = pathSegments.join('/');
        }

        history.push(newPath + window.location.search);
    }, [tabPathName, currentTabParam, history]);

    return onTabChange;
}


export default function MainTasksPage() {
    const { tab } = useParams();
    const onTabChange = useTabRouteSync('tab');
    const activeTab = validateTab(tab) ?? 'todos';

    const tabBarExtraContent = useMemo(() => {
        return (
            <div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem'}}>
                    { activeTab === 'todos' &&
                        <>
                            <ResetTaskGridLink />
                            <TaskDataGridExportButton />
                            <TaskDataGridReloadButton />
                        </>
                    }
                </div>
            </div>
        );
    }, [activeTab]);

    return (
        <TaskDataGridContextProvider>
            <MainContent>
                <PageHead>
                    <BorderedPanel noPadding className="zero-blue">
                        <PageHeader
                            title="To-Dos"
                            subTitle="Create and manage your assigned tasks."
                            action={<CreateTaskButton />}
                        >
                            <TaskFilters />
                        </PageHeader>
                    </BorderedPanel>
                </PageHead>
                <PageContent>
                    <BorderedPanel className="zero-blue">
                        <Tabs
                            activeKey={activeTab}
                            destroyInactiveTabPane
                            onChange={onTabChange}
                            tabBarExtraContent={tabBarExtraContent}
                            items={[
                                {
                                    key: 'todos',
                                    label: "Todos",
                                    children: (
                                        <>
                                            <TaskDataGridModals />
                                            <TaskDataGrid />
                                        </>
                                    ),
                                },
                                // {
                                //     key: 'reports',
                                //     label: "Reports",
                                //     children: null,
                                // },
                            ]}
                        />
                    </BorderedPanel>
                </PageContent>
            </MainContent>
        </TaskDataGridContextProvider>
    )
}

const VALID_TABS = ['todos', 'reports'];

function validateTab(tab) {
    if (VALID_TABS.includes(tab)) {
        return tab;
    }
    return undefined;
}

const ALL_TASKS_FILTER_OPTIONS = [
    {value: '', label: 'My To-Dos'},
    {value: '1', label: 'All To-Dos'},
];

function CreateTaskButton() {
    const {setActiveModal} = useTaskDataGridContext();
    const onClick = () => setActiveModal({key: 'detail', task: null});
    return <Button type="primary" onClick={onClick}>Create To-Do</Button>;
}

function TaskFilters() {
    const user = useCurrentUser();
    const {filters, setFilter} = useTaskDataGridContext();

    return (
        <>
            <div className="row" style={{padding: "0 0.5rem"}}>
                <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                    <DelayedTextInput
                        useAntInput
                        delay={500}
                        style={{width: "100%"}}
                        placeholder="Search..."
                        defaultValue={filters.search || ''}
                        onChange={value => setFilter('search', value)}
                        allowClear={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                    <TimePeriodFilter
                        noWrapperStyle
                        period_query={filters.period || ''}
                        updateQuery={(period) => setFilter('period', period)}
                        periodSelected={() => {}}
                    />
                </div>
            </div>
            <div className="row" style={{padding: "0 0.5rem"}}>
                <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                    <Select
                        style={{width: '100%'}}
                        options={TASK_STATUS_FILTER_OPTIONS}
                        value={filters.status || ''}
                        onChange={(value) => setFilter('status', value)}
                    />
                </div>
                { isAdmin(user) &&
                    <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                        <Select
                            style={{width: '100%'}}
                            options={ALL_TASKS_FILTER_OPTIONS}
                            value={filters.allTasks || ''}
                            onChange={(value) => setFilter('allTasks', value)}
                        />
                    </div>
                }
            </div>
        </>
    )
}
