// @ts-check
import { createContext, useContext, useMemo } from "react";
import { useDataGridContextBase } from "./ZeroDataGrid.hooks";

export const DataGridContext = createContext(null);

export function useDataGridContext() {
    return useContext(DataGridContext);
}

export function DataGridContextProvider({dataGridName, customValue = {}, children}) {
    const ctxValue = useDataGridContextBase(dataGridName);

    const value = useMemo(() => ({...ctxValue, ...customValue}), [ctxValue, customValue]);

    return (
        <DataGridContext.Provider value={value}>
            {children}
        </DataGridContext.Provider>
    );
}

