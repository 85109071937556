// @ts-check
import { deleteCookies, logout, update_auth_token } from "api/zero-api";
import Button from "./Button";
import {LogoutOutlined} from "@ant-design/icons";
import { safe_get } from "other/Helper";

export default function LogoutButton() {
    const logoutClicked = () => {
        sessionStorage.clear();
        logout().then(function (success) {
            success.json().then(success => {
                deleteCookies();
                if (success.kiosk_mode_on) {
                    update_auth_token(safe_get(success, "kiosk_mode_token", undefined));
                    window.location.href = "/kiosk_mode";
                } else {
                    window.location.href = "/login";
                }
            });
        }, function (error) {
            console.log(error);
        });
    }

    return (
        <Button type="danger" onClick={logoutClicked}>
            <LogoutOutlined/>
            <span className="mar-lft-5">Logout</span>
        </Button>
    )
}