// @ts-check

import { Component, useEffect, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';

export default function SignatureField({signature: propsSignature, is_submission: isSubmission, saveSignature: saveSignatureCallback, field_id}) {
    const sigPadRef = useRef(/** @type {SignatureCanvas | null} */(null));
    const prevSignatureRef = useRef(propsSignature);
    const [signature, setSignature] = useState(propsSignature);
    const [saved, setSaved] = useState(!!propsSignature);

    const prevCanvasDimensionsRef = useRef(null);

    useEffect(() => {
        if (prevSignatureRef.current !== propsSignature && !isSubmission) {
            setSignature(propsSignature);
            prevSignatureRef.current = propsSignature;
        }
    }, [propsSignature, isSubmission]);

    useEffect(() => {
        if (!signature && !isSubmission) {
            sigPadRef.current?.off();
        }
    }, [signature, isSubmission]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    const handleWindowResize = () => {
        const canvas = sigPadRef.current?.getCanvas();
        if (canvas && prevCanvasDimensionsRef.current) {
            const {offsetWidth} = prevCanvasDimensionsRef.current;

            if (offsetWidth !== canvas.offsetWidth) {
                // resize canvas and scale points
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.getContext('2d').scale(ratio, ratio);

                const scaleFactor = canvas.offsetWidth / offsetWidth;

                prevCanvasDimensionsRef.current = {
                    offsetWidth: canvas.offsetWidth,
                    offsetHeight: canvas.offsetHeight,
                }

                const sigPad = sigPadRef.current;

                const points = sigPad.toData();
                scaleSigpadPoints(points, scaleFactor);
                sigPad.clear();
                sigPad.fromData(points);
            }
        }
    };

    const clearSignature = () => {
        if (signature) {
            setSignature(null);
            setSaved(false);
        } else {
            sigPadRef.current?.clear();
        }

        saveSignatureCallback(field_id, "");

        setSaved(false);
        sigPadRef.current?.on();
    }

    const saveSignature = () => {
        if (signature) {
            return;
        }
        setSaved(true);
        const canvas = sigPadRef.current?.getTrimmedCanvas();
        const scaledCanvas = scaleCanvas(canvas, 500);
        const data = scaledCanvas.toDataURL("image/png");
        saveSignatureCallback(field_id, data);
        return data;
    }

    return (
        <div className="">
            <div className="print-avoid-breaks" style={{ position: "relative" }}>
                {signature ? (
                    <img className="signature" src={signature} />
                ) : (
                    // @ts-ignore
                    <SignatureCanvas
                        penColor="black"
                        backgroundColor="#f6f6f6"
                        ref={(ref) => {
                            sigPadRef.current = ref;
                            const canvas = ref?.getCanvas();
                            if (canvas && prevCanvasDimensionsRef.current === null) {
                                prevCanvasDimensionsRef.current = {
                                    offsetWidth: canvas.offsetWidth,
                                    offsetHeight: canvas.offsetHeight,
                                }
                            }
                        }}
                        onEnd={saveSignature}
                        clearOnResize={false}
                    />
                )}
            </div>
            {isSubmission && (
                <div className="signature-pad--footer">
                    <div className="signature-pad--actions">
                        <button type="button" className="ButtonLink zero-light-blue" onClick={clearSignature}>
                            Clear
                        </button>
                        <div className="description">
                            {saved ? "Saved" : "Sign above"}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

/**
 * Scales a canvas to a set width, maintaining aspect ratio.
 * @param {HTMLCanvasElement} canvas 
 * @param {number} newWidth 
 * @returns {HTMLCanvasElement} A scaled copy of the canvas
 */
function scaleCanvas(canvas, newWidth) {
    const originalWidth = canvas.width;
    const originalHeight = canvas.height;

    // Calculate the aspect ratio
    const aspectRatio = originalHeight / originalWidth;

    // Calculate the new height maintaining the aspect ratio
    const newHeight = Math.round(newWidth * aspectRatio);

    // Create a temporary canvas to store the original content
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = newWidth;
    tempCanvas.height = newHeight;
    const tempContext = tempCanvas.getContext('2d');
    tempContext.drawImage(canvas, 0, 0, newWidth, newHeight);
    return tempCanvas;
}

function scaleSigpadPoints(points, scaleFactor) {
    for (let i = 0; i < points.length; i++) {
        for (let j = 0; j < points[i].length; j++) {
            points[i][j].x *= scaleFactor;
        }
    }
}