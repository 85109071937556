// @ts-check

import { tasksApi } from "api/zero-api";
import { generateDataGridEventNames } from "components/Shared/ZeroDataGrid/ZeroDataGrid.hooks";
import { AgGridFilter } from "other/agGridHelper";
import { StatusCell, TaskActionsCell, TaskAssigneesCell, TaskTitleCell, UserAvatarCell } from "./TaskDataGrid.cells";
import { upperFirst } from "lodash-es";
import { dateFormatterNoTime } from "other/Helper";
import dayjs from "dayjs";

export const EventNames = generateDataGridEventNames('TaskDataGrid');

export function getColumnDefs() {
    /** @type {DataGridColumnDefs} */
    const defs = [
        {
            headerName: "",
            field: "_actions_",
            width: 50,
            resizable: false,
            sortable: false,
            suppressMovable: true,
            lockPosition: "left",
            cellRenderer: TaskActionsCell,
            cellStyle: {
                padding: 0,
            },
        },
        {
            headerName: "To-Do",
            field: "title",
            cellRenderer: TaskTitleCell,
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Status",
            field: "status",
            width: 120,
            cellRenderer: StatusCell,
            valueFormatter: ({value}) => typeof value === "string" ? upperFirst(value) : "",
            ...AgGridFilter.setFilter({open: 'Open', closed: 'Closed'}, '', '', {addBlank: false}),
        },
        {
            headerName: "Due Date",
            field: "due_date",
            width: 200,
            valueFormatter: ({value}) => value ? dayjs(value).format('MMM D, YYYY') : "",
            cellClass: "dateFormatterNoTime",
            ...AgGridFilter.date,
        },
        {
            headerName: "Assignees",
            field: "assigned_users",
            cellRenderer: TaskAssigneesCell,
            valueFormatter: ({value}) => value ? value.map(u => u.full_name).join(', ') : "",
        },
        {
            headerName: "Opened Date",
            field: "created_at",
            width: 200,
            valueFormatter: ({value}) => value ? dateFormatterNoTime(value) : "",
            cellClass: "dateFormatterNoTime",
            ...AgGridFilter.date,
        },
        {
            headerName: "Opened By",
            field: "created_by",
            cellRenderer: UserAvatarCell,
            valueFormatter: ({value}) => value ? value.full_name : "",
        },
        {
            headerName: "Closed Date",
            field: "closed_at",
            width: 200,
            valueFormatter: ({value}) => value ? dateFormatterNoTime(value) : "",
            cellClass: "dateFormatterNoTime",
            ...AgGridFilter.date,
        },
        {
            headerName: "Closed By",
            field: "closed_by",
            cellRenderer: UserAvatarCell,
            valueFormatter: ({value}) => value ? value.full_name : "",
        },
    ];
    return defs;
}

export async function loadTasks(queryParams) {
    const res = await tasksApi.list(queryParams);
    const {results: tasks, total: totalTasks} = await res.json();
    return {tasks, totalTasks};
}
