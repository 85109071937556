// @ts-check

import ResetGridLink from "components/Shared/DataGrid/ResetGridLink";
import { EventNames } from "./TaskDataGrid.utils";
import { useTaskDataGridContext } from "./TaskDataGrid.context";

export default function ResetTaskGridLink() {
    const { settings } = useTaskDataGridContext();

    return (
        <ResetGridLink
            settings={settings}
            resetTableEventName={EventNames.RESET_TABLE}
            defaultSortModel={null}            
        />
    );
}
