// @ts-check

import ZeroDataGrid from "components/Shared/ZeroDataGrid/ZeroDataGrid";
import { useDataGridSetup } from "components/Shared/ZeroDataGrid/ZeroDataGrid.hooks";
import { useTaskDataGridContext } from "./TaskDataGrid.context";
import { EventNames, getColumnDefs, loadTasks } from "./TaskDataGrid.utils";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { objectIsEmpty } from "other/Helper";

const TASKS_PER_PAGE = 25;

export default function TaskDataGrid() {
    const location = useLocation();
    const {gridRef, settings, setSettings, rowCount, filters} = useTaskDataGridContext();

    const colDefs = useMemo(() => getColumnDefs(), []);

    const getRows = useCallback(async (/** @type {AgGrid.IGetRowsParams} */params) => {
        const setLoading = (isLoading) => {
            if (rowCount === null) {
                gridRef.current?.api?.setGridOption("loading", isLoading);
            }
        }

        const currentPage = Math.floor((params.startRow ?? 0) / TASKS_PER_PAGE) + 1;

        const queryParams = new URLSearchParams();
        queryParams.set('per_page', String(TASKS_PER_PAGE));
        queryParams.set('page', String(currentPage));

        if (filters.search) {
            queryParams.set('s', filters.search);
        }

        if (filters.period) {
            const periodParams = new URLSearchParams(filters.period);
            periodParams.forEach((value, key) => {
                queryParams.set(key, value);
            })
        }

        if (filters.status) {
            queryParams.set('status', filters.status);
        }

        if (filters.allTasks) {
            queryParams.set('all', filters.allTasks);
        }

        if (!objectIsEmpty(params.filterModel)) {
            queryParams.set('ag_filter', JSON.stringify(params.filterModel));
        }

        params.sortModel.forEach(sortModel => {
            let col = sortModel.colId;
            if (sortModel.sort === 'desc') {
                col = `-${col}`;
            }
            queryParams.append('sort_by', col);
        });

        setLoading(true);
        const {tasks, totalTasks} = await loadTasks(queryParams);
        params.successCallback(tasks, totalTasks);
        setLoading(false);
    }, [filters]);

    const {onGridReady, onStateUpdated} = useDataGridSetup({
        eventNames: EventNames,
        autoLoadData: true,
        gridRef,
        colDefs,
        getRows,
        setSettings,
    });

    return (
        <ZeroDataGrid
            gridRef={gridRef}
            columnDefs={colDefs}
            defaultColDef={{
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                }
            }}
            initialState={location.state?.gridState ?? settings}
            onGridReady={onGridReady}
            onStateUpdated={onStateUpdated}
            cacheBlockSize={TASKS_PER_PAGE}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={2}
            infiniteInitialRowCount={rowCount ?? TASKS_PER_PAGE}
            pagination={true}
            paginationPageSizeSelector={false}
            paginationPageSize={rowCount ?? TASKS_PER_PAGE}
            tooltipShowDelay={500}
            tooltipShowMode="standard"
            suppressCellFocus
        />
    );
}