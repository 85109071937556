// @ts-check

import { ReloadOutlined } from '@ant-design/icons';

import { EventNames } from "./TaskDataGrid.utils";
import { useZeroContext } from 'components/ZeroContext';

export default function TaskDataGridReloadButton() {
    const context = useZeroContext();

    return (
        <ReloadOutlined
            className="hover-cursor zero-blue"
            style={{fontSize: '18px'}}
            onClick={() => {context.events.emit(EventNames.REFRESH_DATA)}}
        />
    );
}
